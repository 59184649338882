type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const PauseLarge: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="105"
      height="104"
      viewBox="0 0 105 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M52.4998 92.0834C30.3565 92.0834 12.4165 74.1434 12.4165 52C12.4165 29.8567 30.3565 11.9167 52.4998 11.9167C74.6432 11.9167 92.5832 29.8567 92.5832 52C92.5832 74.1434 74.6432 92.0834 52.4998 92.0834Z"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.8267 43.0734V60.9267"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.1733 43.0734V60.9267"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

PauseLarge.defaultProps = defaultProps;

export default PauseLarge;
