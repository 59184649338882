type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Warning: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="105"
      height="104"
      viewBox="0 0 105 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45.9132 21.4266L14.3172 75.6375C11.8096 80.6404 15.3759 86.6666 20.8927 86.6666L84.0848 86.6666C89.6015 86.6666 93.2236 80.6972 90.6603 75.6375L59.0642 21.4266C56.3337 15.9689 48.6437 15.9689 45.9132 21.4266Z"
        stroke={color || '#FAAD14'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.5 42.9866V58.8466"
        stroke={color || '#FAAD14'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.5 74.0133C54.2949 74.0133 55.75 72.5582 55.75 70.7633C55.75 68.9684 54.2949 67.5133 52.5 67.5133C50.7051 67.5133 49.25 68.9684 49.25 70.7633C49.25 72.5582 50.7051 74.0133 52.5 74.0133Z"
        fill={color || '#FAAD14'}
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

Warning.defaultProps = defaultProps;

export default Warning;
