import { useEffect } from 'react';

import { Route, Routes, useSearchParams } from 'react-router-dom';

import { Home } from 'src/pages/Home';
import { Results } from 'src/pages/Results';
import { Simulates } from 'src/pages/Simulates';
import { SimulateTests } from 'src/pages/SimulateTests';
import { NotFound } from 'src/pages/NotFound';

import { verifyUserRequest } from 'src/redux/actions';
import { useAppDispatch, useAppSelector } from 'src/redux/useAppSelector';
import { UserState } from 'src/redux/reducers/user';

import { Sidebar } from 'src/components/system/Sidebar';

import simulatoroGtm from 'src/shared/simulatoroGtm';

export function PrivateRouter() {
  let windowContext: any = window;
  const userState: UserState = useAppSelector(state => state.user);
  const {
    user: { user },
  } = userState;
  const gtmSimulatoro = new simulatoroGtm(windowContext);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || localStorage.getItem('token');
  const idmUrl =
    process.env.REACT_APP_REDIRECT_URL ||
    'https://dev.idm.academiarafaeltoro.com.br/';
  const callBackURL = window.location.href;

  useEffect(() => {
    if (userState.loading === false && user.name) {
      const data = {
        user_id: user.id_usuario,
        clientFirstName: user.name,
        clientLastName: user.last_name,
        clientemail: user.email,
        clientphone: user.phone,
        pageTitle: document.title,
      };
      gtmSimulatoro.insert(data);
    }
  }, [window.location.pathname, userState.loading]);
  
  useEffect(() => {
    if (token) {
      dispatch(verifyUserRequest(token || ''));
      localStorage.setItem('token', token);
    } else {
      window.location.replace(`${idmUrl}?redirectUrl=${callBackURL}`);
    }
  }, []);

  return (
    <Sidebar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/simulates" element={<Simulates />} />
        <Route path="/simulates/:idCurso" element={<Simulates />} />
        <Route
          path="/simulates/:idCurso/questions/lecture/:testId"
          element={<SimulateTests />}
        />
        <Route
          path="/simulates/:testId/test/:simulateId"
          element={<SimulateTests />}
        />
        <Route path="/results" element={<Results />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Sidebar>
  );
}
