import styled from 'styled-components';

export const SimulateTestContentMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px;
  gap: 20px;

  position: fixed;
  width: 100%;
  height: 100px;
  left: 0px;
  bottom: 0;

  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);

  button {
    box-shadow: none;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 20px;
  }
`;

export const QuestionaryArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 7px;

  font-style: normal;
  font-weight: 500;

  h1 {
    font-size: 1rem;
    line-height: 131%;

    color: var(--gray-800);
  }

  p {
    font-size: 0.875rem;
    line-height: 129%;

    color: var(--gray-400);

    margin: 0;
  }
`;
