import styled from 'styled-components';

interface ContainerProps {
  background: string;
}

interface TitleProps {
  fontSize: string;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-image: linear-gradient(
      100.59deg,
      RGBA(216, 30, 91, 0.4) -8.92%,
      RGBA(0, 10, 102, 0.4) 120.67%
    ),
    url(${({ background }) => background});
  background-blend-mode: unset;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px;
  gap: 50px;

  background: var(--white);

  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  max-width: 630px;

  @media screen and (max-width: 700px) {
    width: 90%;
    padding: 30px;
    gap: 30px;
  }
`;

export const InstrictionsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
`;

export const Title = styled.h1<TitleProps>`
  font-style: normal;
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize};
  line-height: 32px;

  color: var(--gray-800);
`;

export const InformationsArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 14px;

  position: relative;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 22px;

    color: var(--gray-800);

    .informations {
      font-size: 1rem;
      color: var(--red);
      margin-left: 5px;
    }
  }

  svg {
    margin-left: 2px;
    padding-top: 3px;
  }
`;

export const ButtonsArea = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 50px;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const ButtonItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
