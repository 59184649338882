import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: -20px;
`;

export const SharedButtonArea = styled.button`
  width: 100%;
  height: 55px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 15px;
  border: 1px solid var(--Other-Purple, #5768ff);
  background: var(--Other-Purple, #5768ff);
  box-shadow: 0px 6px 15px 0px rgba(87, 134, 255, 0.3);

  border-radius: 10px;

  margin: 30px 0;

  color: var(--escala-de-cinza-0, #fff);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  transition: filter 2s;

  &:hover {
    filter: brightness(0.6);
  }

  > svg {
    margin-left: 12px;
  }
`;
