import { useContext } from 'react';
import { Loading } from 'src/components/system/Loading';

import { SimulateTestsFooterProps } from 'src/components/system/SimulateTestsFooter';
import { SimulateTestsTopbar } from 'src/components/system/SimulateTestsTopbar';
import { TestArea } from 'src/components/system/TestArea';
import { TestAreaQuestionsList } from 'src/components/system/TestAreaQuestionsList';

import { IsMobileContext } from 'src/hooks/useMobile';
import { SimulateTestsStates } from 'src/redux/reducers/simulateTests';
import { SimulateTestsType } from 'src/redux/types/interfaces';
import { useAppSelector } from 'src/redux/useAppSelector';

import { Instructions } from './Instructions';

import { Container } from './styles';

interface SimulateTestsRenderProps
  extends SimulateTestsFooterProps,
  SimulateTestsType {
  startTest: boolean;
  handleStartTest: () => void;
  urlGoBack: string;
  handleSelectAnswer: (
    id_resposta: number,
    id_questao: number,
    questionNumber: number,
  ) => void;
  handleOpenComentedQuestion: () => void;
  handleSelectQuestion: (value: number) => void;
  screenName: string;
}

export function SimulateTestsRender({
  startTest,
  handleStartTest,
  urlGoBack,
  questionNumber,
  handleBackQuestion,
  handlePassQuestion,
  questions,
  handleSelectAnswer,
  handleOpenComentedQuestion,
  handleSelectQuestion,
  screenName,
}: SimulateTestsRenderProps) {
  const simulateTestsStates: SimulateTestsStates = useAppSelector(
    state => state.simulateTests,
  );
  const { isMobile } = useContext(IsMobileContext);
  if (simulateTestsStates.loading) return <Loading />;
  else {
    if (startTest)
      return (
        <Instructions
          handleStartTest={handleStartTest}
          urlGoBack={urlGoBack}
          screenName={screenName}
        />
      );
    else
      return (
        <Container isMobile={isMobile}>
          <SimulateTestsTopbar />
          <TestArea
            questions={questions}
            length={questions?.length}
            handlePassQuestion={handlePassQuestion}
            handleBackQuestion={handleBackQuestion}
            questionNumber={questionNumber}
            handleSelectAnswer={handleSelectAnswer}
            handleOpenComentedQuestion={handleOpenComentedQuestion}
          />
          <TestAreaQuestionsList
            questions={questions}
            questionNumber={questionNumber}
            handleSelectQuestion={handleSelectQuestion}
          />
        </Container>
      );
  }
}
