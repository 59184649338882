import { UserType } from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

export interface UserState {
  user: UserType;
  loading: boolean;
  error: string;
}

const initialState: UserState = {
  user: { id: '', token: '', user: {} },
  error: '',
  loading: false,
};

export function userReducer(
  state = initialState,
  action: { type: string; payload: UserType },
) {
  switch (action.type) {
    case types.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
        user: action.payload,
        error: '',
      };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: '',
      };
    case types.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
