type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Receipt: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.14 2.75V19.62C17.14 20.15 16.94 20.63 16.62 21L15.46 19.38C15.17 18.97 14.58 18.96 14.27 19.36L13.04 20.96C12.74 21.35 12.16 21.35 11.86 20.96L10.62 19.33C10.32 18.94 9.74 18.94 9.44 19.33L8.19 20.96C7.89 21.35 7.31001 21.35 7.01001 20.96L5.76001 19.33C5.46001 18.94 4.89 18.94 4.59 19.33L3.29001 21.03C2.96001 20.65 2.75 20.16 2.75 19.62V4.81C2.75 3.67 3.67 2.75 4.81 2.75H17.14Z"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1401 4.80999V12H19.2001C20.3401 12 21.2601 11.08 21.2601 9.93999V4.79999C21.2601 3.65999 20.3401 2.73999 19.2001 2.73999"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83008 6.91003H14.0601"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83008 10.97H14.0601"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83008 15.08H14.0601"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2.75H19.19"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

Receipt.defaultProps = defaultProps;

export default Receipt;
