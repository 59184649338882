import { RdStationResponseProps } from 'src/redux/types/interfaces';
import { rdStationRequestProps } from 'src/redux/sagas/rdstation';
import * as types from 'src/redux/types';

export function setRdStationRequest(data: rdStationRequestProps): {
  type: string;
  payload: rdStationRequestProps;
} {
  return {
    type: types.SET_RDSTATION_REQUEST,
    payload: data,
  };
}

export function setRdStationuccess(rdstation: RdStationResponseProps): {
  type: string;
  payload: RdStationResponseProps;
} {
  return {
    type: types.SET_RDSTATION_SUCCESS,
    payload: rdstation,
  };
}

export function setRdStationFailure(rdstation: RdStationResponseProps): {
  type: string;
  payload: RdStationResponseProps;
} {
  return {
    type: types.SET_RDSTATION_FAILURE,
    payload: rdstation,
  };
}
