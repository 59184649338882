import {
  AnswersReviewType,
  ResultsReviewType,
} from 'src/redux/types/interfaces';

import {
  CheckedItem,
  QuestionsArea,
  AnswersArea,
  AnswersItem,
  RoundedItem,
} from './styles';

interface QuestionsReviewProps {
  handleSelectRightItem: (
    correta: number,
    marcada: boolean,
    border: boolean,
  ) => string;
  result: ResultsReviewType;
  questionNumber: number;
}

export function QuestionsReview({
  handleSelectRightItem,
  questionNumber,
  result,
}: QuestionsReviewProps) {
  const { enunciado, img_enunciado, id_questao, respostas } = result.questao;
  const dontHaveQuestion =
    respostas?.filter(
      (resposta: AnswersReviewType) => resposta.marcada === true,
    )?.length === 0;

  return (
    <QuestionsArea key={questionNumber} data-testid="test-area">
      <h1>
        Questão {questionNumber + 1}
        <span>({id_questao})</span>
      </h1>
      {enunciado}
      {img_enunciado !== null ? (
        <img
          src={`https://arquivos.academiarafaeltoro.com.br/${img_enunciado}`}
          alt=""
          style={{ marginTop: 10 }}
        />
      ) : null}
      <AnswersArea>
        {respostas?.length > 0 &&
          respostas?.map(
            (
              { resposta, correta, marcada }: AnswersReviewType,
              index: number,
            ) => (
              <AnswersItem
                colorChecked={handleSelectRightItem(correta, marcada, true)}
                backgroundChecked={handleSelectRightItem(
                  correta,
                  marcada,
                  false,
                )}
                key={index}
              >
                <RoundedItem>
                  {marcada ? (
                    <CheckedItem
                      colorChecked={handleSelectRightItem(
                        correta,
                        marcada,
                        true,
                      )}
                    />
                  ) : (
                    <p>&nbsp;</p>
                  )}
                </RoundedItem>
                {resposta}
              </AnswersItem>
            ),
          )}
        <AnswersItem
          colorChecked={handleSelectRightItem(0, dontHaveQuestion, true)}
          backgroundChecked={handleSelectRightItem(0, dontHaveQuestion, false)}
        >
          <RoundedItem>
            <CheckedItem
              colorChecked={handleSelectRightItem(0, dontHaveQuestion, true)}
            />
          </RoundedItem>
          Não sei
        </AnswersItem>
      </AnswersArea>
    </QuestionsArea>
  );
}
