import styled from 'styled-components';

import { TypographStyleProps } from '../shared/types';

export const SubTitleTypograph = styled.h2<TypographStyleProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.125rem;

  color: var(--gray-900);

  margin: 0;
  margin-top: ${({ marginTop }) => (marginTop !== '' ? marginTop : '0')};

  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')}; ;
`;
