import styled from 'styled-components';

export const SimulateTestContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;

  width: 100%;

  margin-top: 50px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 20px;

    button{
      width: 100%;
    }
  }
`;

export const ButtonItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
`;
