import styled from 'styled-components';

interface ButtonContainerAreaDesktopProps {
  open: boolean;
}

interface QuestionButtonDesktopProps {
  answereds: boolean;
  selected: boolean;
}

export const ContainerDesktop = styled.div<ButtonContainerAreaDesktopProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  padding: 20px;
  gap: 15px;

  width: 100%;
  height: ${({ open }) => (open ? 'fit-content' : '70px')};

  background: var(--gray-200);
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);

  transition-property: height;
  transition-duration: 2s;

  @media screen and (max-width: 925px) {
    height: fit-content;
  }
`;

export const QuestionsAreaDesktop = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(17, auto);
  gap: 15px;

  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 925px) {
    grid-template-columns: repeat(10, auto);
  }

  @media screen and (max-width: 635px) {
    grid-template-columns: repeat(4, auto);
  }
`;

export const QuestionButtonDesktop = styled.button<QuestionButtonDesktopProps>`
  background: ${({ selected }) => (selected ? 'var(--red)' : 'none')};
  border-radius: 2px;

  border: 0;

  width: 30px;
  height: 30px;

  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 112%;

  color: ${({ answereds, selected }) =>
    selected
      ? 'var(--white)'
      : answereds
      ? 'var(--gray-900)'
      : 'var(--gray-300)'};
`;

export const ButtonAreaDesktop = styled.div<ButtonContainerAreaDesktopProps>`
  width: 120px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  p {
    margin-bottom: 0 !important;
  }

  button {
    width: 30px;
    height: 30px;

    background: none;
    border: none;

    cursor: pointer;

    svg {
      rotate: ${({ open }) => (open ? '180deg' : '0')};

      filter: rotate 1s;
    }
  }
`;
