import * as types from 'src/redux/types';
import { SimulatesType } from 'src/redux/types/interfaces';

export interface SimulateState extends SimulatesType {
  loading: boolean;
  error: string;
}

const initialState: SimulateState = {
  simulates: null,
  error: '',
  loading: false,
};

export function simulatesReducer(
  state = initialState,
  action: { type: string; payload: SimulatesType },
) {
  switch (action.type) {
    case types.GET_SIMULATES_REQUEST:
      return {
        ...state,
        loading: true,
        simulates: action.payload,
        error: '',
      };
    case types.GET_SIMULATES_SUCCESS:
      return {
        ...state,
        loading: false,
        simulates: action.payload,
        error: '',
      };
    case types.GET_SIMULATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
