import { all, call, put, takeEvery } from '@redux-saga/core/effects';

import {
  QuestionsProps,
  SimulateAnswerProps,
  SimulateSendDataProps,
  SimulateTestRequestProps,
} from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

import {
  getSimulateTestsSuccess,
  getSimulateTestsFailure,
  setSimulateAnswerSuccess,
  setSimulateAnswerFailure,
  sendSimulateTestDataSuccess,
  sendSimulateTestDataFailure,
} from 'src/redux/actions';

import api from 'src/services/api';

let simulateTestsApi: QuestionsProps[];
let simulateAnswerFinishedApi: boolean;

async function simulateTestsRequest({
  token,
  id_simulado,
}: SimulateTestRequestProps) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const request = await api.get(
      `simulated/list/questions/${id_simulado}`,
      config,
    );
    const response = await request.data;
    simulateTestsApi = response;
    return true;
  } catch (e) {
    return false;
  }
}

async function simulateSendData({
  data,
  id_aluno,
  id_simulado,
  token,
}: SimulateSendDataProps) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const request = await api.post(
      `services/student/${id_aluno}/lecture/${id_simulado}/tests/finish`,
      data,
      config,
    );
    const response = await request.data;

    simulateAnswerFinishedApi = true;
    return true;
  } catch (e) {
    return false;
  }
}

async function simulateAnswerRequest({
  data,
  id_usuario,
  id_simulado,
  token,
}: SimulateAnswerProps) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const request = await api.post(
      `services/student/${id_usuario}/lecture/${id_simulado}/tests/ongoing/store`,
      data,
      config,
    );
    simulateAnswerFinishedApi = true;
    return true;
  } catch (e) {
    return false;
  }
}

export function* simulateTestsData(action: {
  type: string;
  payload: SimulateTestRequestProps;
}) {
  try {
    yield call(simulateTestsRequest, action.payload);
    yield put(getSimulateTestsSuccess(simulateTestsApi));
  } catch (e) {
    yield put(getSimulateTestsFailure(simulateTestsApi));
  }
}

export function* simulateAnswerData(action: {
  type: string;
  payload: SimulateAnswerProps;
}) {
  try {
    yield call(simulateAnswerRequest, action.payload);
    yield put(setSimulateAnswerSuccess());
  } catch (e) {
    yield put(setSimulateAnswerFailure());
  }
}

export function* simulateFinishData(action: {
  type: string;
  payload: SimulateSendDataProps;
}) {
  try {
    yield call(simulateSendData, action.payload);
    yield put(sendSimulateTestDataSuccess());
  } catch (e) {
    yield put(sendSimulateTestDataFailure());
  }
}

export default all([
  takeEvery(types.GET_SIMULATE_TESTS_REQUEST, simulateTestsData),
  takeEvery(types.SET_SIMULATE_ANSWER_REQUEST, simulateAnswerData),
  takeEvery(types.SEND_DATA_REQUEST, simulateFinishData),
]);
