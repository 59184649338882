import { useContext } from 'react';

import { ModalContext } from 'src/hooks/useModal';
import { TimerContext } from 'src/hooks/useTimer';

import { ModalFinishRender } from './render';

interface ModalFinishProps {
  handleFinishSimulate: () => void;
}

export function ModalFinish({ handleFinishSimulate }: ModalFinishProps) {
  const { setRunning, setTime } = useContext(TimerContext);
  const { setModalChildren, setOpen } = useContext(ModalContext);

  function handleGetoutTest() {
    setOpen(false);
    setModalChildren(null);
    setRunning(false);

    handleFinishSimulate()
  }

  function handleContinueTest() {
    setOpen(false);
    setModalChildren(null);
    setRunning(true);
  }

  return (
    <ModalFinishRender
      handleGetoutTest={handleGetoutTest}
      handleContinueTest={handleContinueTest}
    />
  );
}
