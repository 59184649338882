import * as types from 'src/redux/types';
import { CuponsType } from 'src/redux/types/interfaces';

export interface CuponsState {
  cupons: CuponsType;
  loading: boolean;
  error: string;
}

const initialState: CuponsState = {
  cupons: {},
  error: '',
  loading: false,
};

export function cuponsReducer(
  state = initialState,
  action: { type: string; payload: CuponsType },
) {
  switch (action.type) {
    case types.GET_CUPONS_REQUEST:
      return {
        ...state,
        loading: true,
        cupons: action.payload,
        error: '',
      };
    case types.GET_CUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        cupons: action.payload,
        error: '',
      };
    case types.GET_CUPONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
