type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const CheckmarkCircle: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.0007 31.9168C8.48398 31.9168 1.58398 25.0168 1.58398 16.5002C1.58398 7.9835 8.48398 1.0835 17.0007 1.0835C25.5173 1.0835 32.4173 7.9835 32.4173 16.5002C32.4173 25.0168 25.5173 31.9168 17.0007 31.9168Z"
        stroke={color || '#5768FF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2341 19.133L13.3008 16.1997"
        stroke={color || '#5768FF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.101 13.2666L16.2344 19.1333"
        stroke={color || '#5768FF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

CheckmarkCircle.defaultProps = defaultProps;

export default CheckmarkCircle;
