import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { ModalContext } from 'src/hooks/useModal';
import { TimerContext } from 'src/hooks/useTimer';

import { ModalGetoutRender } from './render';

export function ModalGetout() {
  const { setRunning, setTime } = useContext(TimerContext);
  const { setModalChildren, setOpen } = useContext(ModalContext);

  const navigate = useNavigate();

  function handleGetoutTest() {
    setOpen(false);
    setModalChildren(null);
    setTime(0);
    setRunning(false);

    navigate('/');
  }

  function handleContinueTest() {
    setOpen(false);
    setModalChildren(null);
    setRunning(true);
  }

  return (
    <ModalGetoutRender
      handleGetoutTest={handleGetoutTest}
      handleContinueTest={handleContinueTest}
    />
  );
}
