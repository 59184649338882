import { Link } from 'react-router-dom';

import moment from 'moment';

import ArrowRight from 'src/assets/svgs/arrowRight';
import PieChart from 'src/assets/svgs/pieChart';
import Timer from 'src/assets/svgs/timer';
import TwoSpeechBubbles from 'src/assets/svgs/twoSpeechBubbles';

import {
  Content,
  Footer,
  Informations,
  LeftRightAreaFooter,
  TimeQuestions,
  Title,
} from './styles';
import { CoursesProps } from 'src/redux/types/interfaces';

export function Courses({
  nome,
  class_time,
  id_aula,
  id_curso,
  imagem,
}: CoursesProps) {
  moment.locale('pt-br');

  return (
    <Link to={`/simulates/${id_curso}`} data-testid="courses-area">
      <Content>
        <Title>
          <p>{nome}</p>
          <span>
            <ArrowRight color="#5768FF" width={18} height={18} />
          </span>
        </Title>
        <Informations>
          <TimeQuestions>
            {!!class_time ? (
              <>
                <Timer color="#5768FF" width={22} height={22} />
                <p>{String(class_time)?.slice(0, -3)} horas</p>
              </>
            ) : (
              <>
                <Timer color="#5768FF" width={22} height={22} />
                <p>Sem tempo definido</p>
              </>
            )}
          </TimeQuestions>
         {/*  <TimeQuestions>
            <TwoSpeechBubbles color="#5768FF" width={30} height={30} />
            <p>{total} questões</p>
          </TimeQuestions> */}
        </Informations>
        {/* {generalAverage && courseDate && (
          <Footer>
            <LeftRightAreaFooter>
              <PieChart color="#5768FF" width={23} height={23} />
              <p>Sua média geral foi de {generalAverage}%</p>
            </LeftRightAreaFooter>
            <LeftRightAreaFooter>
              {String(moment(courseDate).locale('pt-br').format('ll'))}
            </LeftRightAreaFooter>
          </Footer>
        )} */}
      </Content>
    </Link>
  );
}
