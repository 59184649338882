type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const ArrowUp: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.1563 22.6562L15.1562 7.38123"
        stroke={color || 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1562 7.34375L10.1563 12.3438"
        stroke={color || 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1562 7.34375L20.1563 12.3437"
        stroke={color || 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

ArrowUp.defaultProps = defaultProps;

export default ArrowUp;
