type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Star: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9781 11.1973C30.8583 11.2732 31.2149 12.3697 30.5476 12.9488L23.7033 18.889C23.4235 19.1317 23.3009 19.5092 23.3846 19.8701L25.431 28.6959C25.6306 29.557 24.6968 30.2347 23.9401 29.7779L16.182 25.0955C15.8642 24.9036 15.4663 24.9036 15.1485 25.0955L7.39425 29.7756C6.63693 30.2327 5.7027 29.5537 5.90366 28.6923L7.96146 19.8714C8.04579 19.5099 7.92294 19.1314 7.64238 18.8884L0.789978 12.9521C0.12138 12.3729 0.478786 11.2747 1.36021 11.1999L10.3811 10.4346C10.7506 10.4032 11.0725 10.1699 11.2172 9.82836L14.7452 1.5043C15.0897 0.691515 16.2415 0.6912 16.5865 1.5038L20.1135 9.81303C20.2581 10.1538 20.5792 10.3868 20.9481 10.4186L29.9781 11.1973ZM9.39858 25.4502L15.6653 21.6669L21.9486 25.4669L20.2819 18.3336L25.8153 13.5336L18.5153 12.9002L15.6653 6.16691L12.8319 12.8836L5.53192 13.5169L11.0653 18.3169L9.39858 25.4502Z"
        fill={color || '#5768FF'}
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

Star.defaultProps = defaultProps;

export default Star;
