import Checkmark from 'src/assets/svgs/checkmark';

import { Button } from 'src/components/system/Button';

import { DoubleButtonsArea, ModalArea } from '../styles/commom';

interface ModalGetoutRenderProps {
  handleGetoutTest: () => void;
  handleContinueTest: () => void;
}

export function ModalFinishRender({
  handleContinueTest,
  handleGetoutTest,
}: ModalGetoutRenderProps) {
  return (
    <ModalArea data-testid="modal-get-out">
      <Checkmark width={105} height={105} color="#62CF7A" />
      <span>Tem certeza que deseja finalizar a prova?</span>
      <DoubleButtonsArea>
        <Button
          width="250px"
          backgroundColor="#ffffff"
          buttonColor="#222B82"
          borderColor="#222B82"
          onClick={handleContinueTest}
          label={<span>Voltar</span>}
        />
        <Button
          width="250px"
          backgroundColor="#D81E5B"
          borderColor="#D81E5B"
          buttonColor="#ffffff"
          onClick={handleGetoutTest}
          label={<span>Finalizar</span>}
        />
      </DoubleButtonsArea>
    </ModalArea>
  );
}
