import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import MagicWand from 'src/assets/svgs/magicWand';
import Stopwatch from 'src/assets/svgs/stopwatch';

import { BoxResultsArea } from 'src/components/system/BoxResultsArea';
import { SubTitle } from 'src/components/typograph/SubTitle';
import { Title } from 'src/components/typograph/Title';
import { CoursesProps, ResultsProps } from 'src/redux/types/interfaces';

import { IsMobileContext } from 'src/hooks/useMobile';

import messages from './utils/messages.json';
import courses_message from './utils/courses_message.json';

import {
  ButtonFooter,
  ButtonResultsTest,
  Container,
  Content,
  CourseTitle,
  CupomArea,
  FooterContent,
  InfomationsAboutTest,
  InformationAboutWhatDoing,
  LeftRightAreaContent,
  PieGraph,
  ResultsArea,
  TwoColumnsGrid,
  WatchArea,
} from './styles';
import { CuponsState } from 'src/redux/reducers/cupons';
import { UserState } from 'src/redux/reducers/user';
import { useAppSelector } from 'src/redux/useAppSelector';
import { Button } from 'src/components/system/Button';
// import ShareButton from 'src/components/system/ShareButton';
import ArrowLeft from 'src/assets/svgs/arrowLeft';

import { ButtonItem } from '../Simulates/styles';

import './utils/modal.styles.css';
import { SimulateState } from 'src/redux/reducers/simulates';
import { arrayCourseCategory } from 'src/shared/utils';
import ShareButton from 'src/components/system/ShareButton';

interface ResultsRenderProps {
  result: ResultsProps[] | any;
  percent: string;
  total_acertos: string;
  tempo_gasto: string;
  course: CoursesProps[];
  copyTextToClipboard: (value: string) => void;
  handleOpenReviewModal: () => void;
  totalQuestions: number;
  onOpenOtherResults: (percent: number, timeTest: string) => void;
}

export function ResultsRender({
  percent,
  result,
  total_acertos,
  tempo_gasto,
  course,
  copyTextToClipboard,
  handleOpenReviewModal,
  totalQuestions,
  onOpenOtherResults,
}: ResultsRenderProps) {
  const { isMobile } = useContext(IsMobileContext);
  const navigate = useNavigate();
  const userState: UserState = useAppSelector(state => state.user);
  const {
    user: {
      token,
      user: { name },
    },
  } = userState;

  const pageCourse = course[0];
  const COURSE_TOTAL_QUESTIONS = totalQuestions;
  const MINIMUM_RECOMMENDED_APPROVED = 80;

  // Bloco comentado pra utilização pós MVP

  // const totalTime =
  //   parseInt(String(course[0]?.class_time)?.slice(0, 2)) * 60 +
  //   parseInt(String(course[0]?.class_time)?.slice(3, 5));
  // const currentTime =
  //   parseInt(String(tempo_gasto)?.slice(0, 2)) * 60 +
  //   parseInt(String(tempo_gasto)?.slice(3, 5));

  const cuponsState: CuponsState = useAppSelector(state => state.cupons);
  const simulatesState: SimulateState = useAppSelector(
    state => state.simulates,
  );

  const { simulates } = simulatesState;
  const { cupons } = cuponsState;

  const CourseName = simulates
    ? arrayCourseCategory[simulates.id_course_category - 1].replace('_', '-')
    : '';

  const passou = () => {
    if (parseInt(percent) > 79) return 3;
    else if (parseInt(percent) <= 79 && parseInt(percent) > 69) return 2;
    else if (parseInt(percent) <= 69 && parseInt(percent) > 49) return 1;
    else return 0;
  };

  return (
    <Container>
      <Button
        width="fit-content"
        paddingButton={isMobile ? '50px 20px' : '80px 0px 20px 0px'}
        backgroundColor="none"
        buttonColor="#5768FF"
        onClick={() => navigate('/')}
        borderColor="none"
        data-testid="goout-button"
        height="0px"
        label={
          <ButtonItem>
            <ArrowLeft width={27} height={27} color="#5768FF" />
            Voltar
          </ButtonItem>
        }
      />
      <Content>
        <TwoColumnsGrid>
          <LeftRightAreaContent width="55%" alignItems="flex-start">
            <CourseTitle>
              <span>{result[result.length - 1]?.aula?.titulo}</span>
            </CourseTitle>
            <Title value={`${messages[passou()].title}`} marginTop="20px" />
            {messages[passou()].messages.map((value, index) => {
              if (value === '$$courses_message$$') {
                return (
                  <InformationAboutWhatDoing
                    className={index === 0 ? 'first-item' : ''}
                    key={index}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          courses_message[
                            CourseName as keyof typeof courses_message
                          ],
                      }}
                    />
                  </InformationAboutWhatDoing>
                );
              }

              return (
                <InformationAboutWhatDoing
                  className={index === 0 ? 'first-item' : ''}
                  key={index}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: value.replaceAll('$$course$$', CourseName),
                    }}
                  />
                </InformationAboutWhatDoing>
              );
            })}
          </LeftRightAreaContent>
          <LeftRightAreaContent width="39%">
            {cupons?.cupom && (
              <CupomArea>
                <p>Cupom</p>
                <div>
                  <span> {cupons?.cupom} </span>
                </div>
                <button
                  onClick={() => copyTextToClipboard(cupons?.cupom || '')}
                >
                  <MagicWand width={18} height={18} />
                  Clique aqui e use o cupom
                </button>
              </CupomArea>
            )}
            <ButtonResultsTest onClick={handleOpenReviewModal}>
              Fazer Revisão
            </ButtonResultsTest>
            {isMobile && (
              <ShareButton
                token={token}
                isStudent={true}
                type={'modular'}
                points={[parseInt(total_acertos), totalQuestions]}
                name={name}
                title={result[result.length - 1]?.aula?.titulo}
              />
            )}
          </LeftRightAreaContent>
        </TwoColumnsGrid>
        <SubTitle fontWeight="600" value="Confira seu desempenho abaixo:" />
        <TwoColumnsGrid>
          <LeftRightAreaContent
            width="47%"
            alignItems="center"
            boxShadow="0px 3px 20px rgba(0, 0, 0, 0.08)"
            padding="40px"
            gap="30px"
          >
            <PieGraph percent={parseInt(percent)}>
              <h1>{percent}%</h1>
            </PieGraph>
            <InfomationsAboutTest>
              Você acertou <span>{total_acertos}</span> de{' '}
              <span>{totalQuestions}</span> questões.
            </InfomationsAboutTest>
          </LeftRightAreaContent>
          <LeftRightAreaContent
            width="47%"
            alignItems="center"
            boxShadow="0px 3px 20px rgba(0, 0, 0, 0.08)"
            padding="40px"
            gap="30px"
          >
            <InfomationsAboutTest>Você fez a prova em:</InfomationsAboutTest>
            <WatchArea>
              <Stopwatch width={65} height={65} />
              <h1>
                {tempo_gasto.split(':')[0]}h {tempo_gasto.split(':')[1]}min
              </h1>
            </WatchArea>
            <InfomationsAboutTest>
              O tempo limite é{' '}
              {pageCourse?.class_time
                ? String(pageCourse?.class_time)?.slice(0, 2)
                : '03'}
              h e{' '}
              {pageCourse?.class_time
                ? String(pageCourse?.class_time)?.slice(3, 5)
                : '50'}
              min
            </InfomationsAboutTest>
          </LeftRightAreaContent>
        </TwoColumnsGrid>
        <FooterContent>
          <SubTitle fontWeight="600" value="Resultado de cada simulado:" />
          <ResultsArea>
            {result.map((r: ResultsProps, index: number) => {
              if (index !== result?.length - 1) {
                const totalRecommended = Math.ceil(
                  (COURSE_TOTAL_QUESTIONS * MINIMUM_RECOMMENDED_APPROVED) / 100,
                );
                const percentSet = parseInt(
                  ((r?.total_acertos / COURSE_TOTAL_QUESTIONS) * 100).toFixed(
                    0,
                  ),
                );
                const missed = totalRecommended - r?.total_acertos;
                const percentMissed = (
                  (missed / totalRecommended) *
                  100
                ).toFixed(0);

                const handleOpenBox = () =>
                  onOpenOtherResults(percentSet, r?.tempo_prova.slice(0, -3));

                return (
                  <BoxResultsArea
                    key={index}
                    title={r.aula.titulo}
                    totalQuestion={COURSE_TOTAL_QUESTIONS}
                    percentTotalQuestion={100}
                    hits={r.total_acertos}
                    percentHits={percentSet}
                    academyAverage={totalRecommended}
                    percentAcademyAverage={MINIMUM_RECOMMENDED_APPROVED}
                    missed={missed}
                    percentMissed={parseInt(percentMissed)}
                    handleOpenBox={handleOpenBox}
                  />
                );
              }
            })}
          </ResultsArea>
        </FooterContent>
        <ButtonFooter
          onClick={() => {
            window.open('https://www.academiarafaeltoro.com.br/cursos/');
          }}
        >
          Conheça nossos cursos
        </ButtonFooter>
      </Content>
    </Container>
  );
}
