type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const TwoSpeechBubbles: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3375 24.9625C13.0375 24.9875 12.7375 25 12.4375 25C10.975 25 9.58749 24.65 8.34999 24.0375L3.48749 24.7375L4.22501 19.85H4.2375C3.7375 18.75 3.4375 17.5375 3.4375 16.25C3.4375 13.8875 4.39999 11.75 5.94999 10.175"
        stroke={color || '#5768FF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.075 13.75C14.075 14.0005 13.8632 14.225 13.575 14.225C13.2869 14.225 13.075 14.0005 13.075 13.75C13.075 13.4995 13.2869 13.275 13.575 13.275C13.8632 13.275 14.075 13.4995 14.075 13.75Z"
        stroke={color || '#5768FF'}
      />
      <path
        d="M18.075 13.75C18.075 14.0005 17.8632 14.225 17.575 14.225C17.2869 14.225 17.075 14.0005 17.075 13.75C17.075 13.4995 17.2869 13.275 17.575 13.275C17.8632 13.275 18.075 13.4995 18.075 13.75Z"
        fill={color || '#5768FF'}
        stroke={color || '#5768FF'}
      />
      <path
        d="M22.0625 13.75C22.0625 14.0005 21.8507 14.225 21.5625 14.225C21.2743 14.225 21.0625 14.0005 21.0625 13.75C21.0625 13.4995 21.2743 13.275 21.5625 13.275C21.8507 13.275 22.0625 13.4995 22.0625 13.75Z"
        stroke={color || '#5768FF'}
      />
      <path
        d="M25.775 17.35L26.5125 22.2375L21.65 21.5375C20.775 21.975 19.825 22.275 18.8125 22.4125C18.4125 22.475 17.9875 22.5 17.5625 22.5C12.5875 22.5 8.57501 18.5875 8.57501 13.75C8.57501 11.3375 9.56251 9.1875 11.1875 7.6C12.8 5.9875 15.0625 5 17.575 5C22.55 5 26.5625 8.9125 26.5625 13.75C26.5625 15.0375 26.2625 16.25 25.7625 17.35H25.775V17.35Z"
        stroke={color || '#5768FF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

TwoSpeechBubbles.defaultProps = defaultProps;

export default TwoSpeechBubbles;
