import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Loading } from 'src/components/system/Loading';
import { SidebarContext } from 'src/components/system/Sidebar';

import {
  getCourseRequest,
  setRdStationRequest,
  setTrafficValues,
} from 'src/redux/actions';
import { CoursesState } from 'src/redux/reducers/courses';
import { UserState } from 'src/redux/reducers/user';

import { useAppDispatch, useAppSelector } from 'src/redux/useAppSelector';

import { HomeRender } from './render';

import simulatoroGtm from 'src/shared/simulatoroGtm';

export function Home() {
  let windowContext: any = window;
  const { setStartTestSidebar } = useContext(SidebarContext);
  const coursesState: CoursesState = useAppSelector(state => state.courses);
  const userState: UserState = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || localStorage.getItem('token');

  const traffic_medium = searchParams.get('utm_medium');
  const traffic_campaign = searchParams.get('utm_campaign');
  const traffic_value = searchParams.get('utm_value');
  const traffic_source = searchParams.get('utm_source');
  const traffic_items = {
    traffic_medium: !traffic_medium ? 'cpc' : traffic_medium,
    traffic_campaign: !traffic_campaign ? 'easter-50-off' : traffic_campaign,
    traffic_value: !traffic_value ? 'easter eggs' : traffic_value,
    traffic_source: !traffic_source ? 'Google' : traffic_source,
  };
  const gtmSimulatoro = new simulatoroGtm(windowContext);
  const {
    user: { user },
  } = userState;

  useEffect(() => {
    document.title = 'Home | ART - Simulatoro';
    dispatch(getCourseRequest(token || ''));
    setStartTestSidebar(false);
  }, []);

  useEffect(() => {
    if (userState.loading === false && user.email) {
      gtmSimulatoro.insert({
        event_type: 'CONVERSION',
        event_family: 'Simulatoro',
        payload: {
          conversion_identifier: 'Simulatoro',
          email: user.email,
          ...traffic_items,
        },
      });

      const rdStationRequestProps = {
        token: token || '',
        body: {
          ...traffic_items,
          conversion_identifier: 'Simulatoro',
          tags: '',
        },
      };
      dispatch(setTrafficValues(traffic_items));
      dispatch(setRdStationRequest(rdStationRequestProps));
    }
  }, [userState.loading]);

  useEffect(() => {
    if (
      coursesState.loading === false &&
      Array.isArray(coursesState.courses) &&
      coursesState.courses.length > 0
    ) {
      localStorage.setItem('courses', JSON.stringify(coursesState.courses));
    }
  }, [coursesState.loading]);

  if (coursesState.loading) return <Loading />;
  else return <HomeRender />;
}
