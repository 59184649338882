import { TrafficProps } from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

export function setTrafficValues(data: TrafficProps): {
  type: string;
  payload: TrafficProps;
} {
  return {
    type: types.SET_TRAFFIC_VALUES,
    payload: data,
  };
}

export function getTrafficValues(data: TrafficProps): {
  type: string;
  payload: TrafficProps;
} {
  return {
    type: types.GET_TRAFFIC_VALUES,
    payload: data,
  };
}
