import styled from 'styled-components';

export const Title = styled.div`
  width: 100%;
  padding: 0px 0 26px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid var(--gray-300);

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.375rem;

    color: var(--gray-800);

    margin: 0;
  }

  span {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.5625rem;

    color: var(--blue-400);

    margin: 0 20px;
  }
`;

export const Footer = styled.div`
  width: calc(100% + 40px);
  padding: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: var(--blue-100);
  margin: -20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;

  background: var(--white);
  border-radius: 10px;

  cursor: pointer;

  transition: 0.2s border;
  
  border: 2px solid transparent;

  &:hover {
    border: 2px solid var(--blue-400);

    ${Title} > span::before {
      content: 'Começar';
      margin: -5px 8px;
    }

    ${Footer} {
      border-top: 2px solid var(--blue-400);
    }
  }
`;

export const Informations = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TimeQuestions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 5px;
  gap: 20px;

  margin-bottom: 10px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;

    color: var(--gray-800);

    margin: 0;
  }
`;

export const LeftRightAreaFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 16px;
  }

  p {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.3125rem;

    color: var(--gray-800);
  }
`;
