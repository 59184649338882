type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const DesktopMonitor: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.19 17.16H4.79999C3.65999 17.16 2.73999 16.24 2.73999 15.1V4.79999C2.73999 3.65999 3.65999 2.73999 4.79999 2.73999H19.19C20.33 2.73999 21.25 3.65999 21.25 4.79999V15.1C21.25 16.24 20.33 17.16 19.19 17.16Z"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.14 17.16L9.55005 20.71"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.76 17.33L14.32 20.67"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.83008 21.25H16.0501"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 14.04H21.19"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

DesktopMonitor.defaultProps = defaultProps;

export default DesktopMonitor;
