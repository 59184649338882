import { useContext } from 'react';

import { IsMobileContext } from 'src/hooks/useMobile';

import { ProgressArea, ProgressLine, ProgressLineProps } from './styles';

interface ProgressBarProps extends Omit<ProgressLineProps, 'isMobile'> {}

export function ProgressBar({ currentValue, totalValue }: ProgressBarProps) {
  const { isMobile } = useContext(IsMobileContext);

  return (
    <ProgressArea data-testid="progress-area" isMobile={isMobile}>
      <ProgressLine
        data-testid="progress-bar"
        currentValue={currentValue}
        totalValue={totalValue}
        isMobile={isMobile}
      />
    </ProgressArea>
  );
}
