type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Shared: React.FC<Props> = ({ color, width, height, ...props }: Props) => {
  return (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.31996 18.6933C10.8516 18.6933 12.0933 17.4875 12.0933 16C12.0933 14.5125 10.8516 13.3066 9.31996 13.3066C7.78829 13.3066 6.54663 14.5125 6.54663 16C6.54663 17.4875 7.78829 18.6933 9.31996 18.6933Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.76 9.33331C24.2475 9.33331 25.4533 8.12746 25.4533 6.63997C25.4533 5.15249 24.2475 3.94666 22.76 3.94666C21.2725 3.94666 20.0667 5.15249 20.0667 6.63997C20.0667 8.12746 21.2725 9.33331 22.76 9.33331Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.76 28.0533C24.2475 28.0533 25.4533 26.8474 25.4533 25.3599C25.4533 23.8725 24.2475 22.6666 22.76 22.6666C21.2725 22.6666 20.0667 23.8725 20.0667 25.3599C20.0667 26.8474 21.2725 28.0533 22.76 28.0533Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.3066 13.92L19.7733 8.71997"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.7733 23.2799L12.3066 18.08"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

Shared.defaultProps = defaultProps;

export default Shared;
