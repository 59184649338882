import styled from 'styled-components';

export const QuestionsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;

  margin-bottom: 30px;

  color: var(--black);
  font-style: normal;

  h1 {
    padding: 0px;

    font-weight: 600;
    font-size: 1.5rem;

    span {
      font-weight: 400;
      font-size: 1.125rem;

      color: var(--gray-400);

      margin-left: 17px;
    }
  }

  p {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 139%;
  }

  img {
    width: 100%;
    margin-top: 10px;
  }
`;

export const AnswersArea = styled.div`
  width: 100%;
  gap: 10px;

  margin-bottom: 40px;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface AnswersItemProps {
  colorChecked: string;
  backgroundChecked: string;
}

export const AnswersItem = styled.span<AnswersItemProps>`
  width: 100%;

  background: ${({ backgroundChecked }) => backgroundChecked};

  padding: 15px;
  margin: 0 20px;

  border-radius: 10px;
  border: 2px solid ${({ colorChecked }) => colorChecked};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 10px;
`;

export const RoundedItem = styled.div`
  width: 14px;
  height: 14px;

  display: block;

  border: 1.5px solid var(--gray-800);
  border-radius: 100%;

  position: relative;
`;

interface CheckedItemProps {
  colorChecked: string;
}

export const CheckedItem = styled.div<CheckedItemProps>`
  position: absolute;

  left: 3.5px;
  top: 0.5px;

  width: 4px;
  height: 8px;

  border: 2px solid ${({ colorChecked }) => colorChecked};
  border-width: 0 2px 2px 0;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`;
