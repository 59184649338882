import * as types from 'src/redux/types';
import { TrafficProps } from 'src/redux/types/interfaces';

const initialState: TrafficProps = {
  traffic_medium: '',
  traffic_campaign: '',
  traffic_value: '',
  traffic_source: '',
};

export function trafficReducer(
  state = initialState,
  action: { type: string; payload: TrafficProps },
) {
  switch (action.type) {
    case types.SET_TRAFFIC_VALUES:
      return {
        traffic_medium: action.payload.traffic_medium,
        traffic_campaign: action.payload.traffic_campaign,
        traffic_value: action.payload.traffic_value,
        traffic_source: action.payload.traffic_source,
      };
    case types.GET_TRAFFIC_VALUES:
      return state;
    default:
      return state;
  }
}
