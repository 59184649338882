import { all, call, put, takeEvery } from '@redux-saga/core/effects';

import { CuponsType } from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

import { getCuponsuccess, getCuponsFailure } from 'src/redux/actions';

import api from 'src/services/api';

let cuponsApi: CuponsType;

export interface cuponsRequestProps {
  token: string;
  date: string;
  course: number;
  curso_cupom_simulatoro: number | null;
}

async function cuponsRequest({
  course,
  date,
  token,
  curso_cupom_simulatoro,
}: cuponsRequestProps) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const body = {
    date,
    course,
    curso_cupom_simulatoro,
  };

  try {
    const request = await api.post(
      'services/descontos/createCouponAfterFinishSimulated',
      body,
      config,
    );
    const response = await request.data;
    cuponsApi = response;
    return true;
  } catch (e) {
    return false;
  }
}

export function* cuponsData(action: {
  type: string;
  payload: cuponsRequestProps;
}) {
  try {
    yield call(cuponsRequest, action.payload);
    yield put(getCuponsuccess(cuponsApi));
  } catch (e) {
    yield put(getCuponsFailure(cuponsApi));
  }
}

export default all([takeEvery(types.GET_CUPONS_REQUEST, cuponsData)]);
