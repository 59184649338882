import { useContext } from 'react';

import { ModalContext } from 'src/hooks/useModal';
import { TimerContext } from 'src/hooks/useTimer';

import { ModalQuestionRender } from './render';

import { useAppSelector } from 'src/redux/useAppSelector';
import { SimulateState } from 'src/redux/reducers/simulates';

import { arrayCourseFamilyLink } from 'src/shared/utils';

interface ModalQuestionProps {
  //link: string;
  simulateName: string;
  questionNumber: number;
}

export function ModalQuestion({
  //link,
  simulateName,
  questionNumber,
}: ModalQuestionProps) {
  const simulatesState: SimulateState = useAppSelector(
    state => state.simulates,
  );
  const { simulates } = simulatesState;
  const courseFamily = simulates
    ? arrayCourseFamilyLink[simulates?.id_course_category - 1]
    : '';
  const { setRunning } = useContext(TimerContext);
  const { setModalChildren, setOpen } = useContext(ModalContext);

  function htmlDecode(input: string) {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  }

  function handleContinueTest() {
    setOpen(false);
    setModalChildren(null);
    setRunning(true);
  }

  return (
    <ModalQuestionRender
      handleContinueTest={handleContinueTest}
      htmlDecode={htmlDecode}
      /* link={link} */
      simulateName={simulateName}
      questionNumber={questionNumber}
      courseFamily={courseFamily}
    />
  );
}
