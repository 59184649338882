import {
  QuestionsProps,
  SimulateAnswerProps,
  SimulateSendDataProps,
  SimulateTestRequestProps,
  SimulateTestsType,
} from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

export function getSimulateTestsRequest(values: SimulateTestRequestProps): {
  type: string;
  payload: SimulateTestRequestProps;
} {
  return {
    type: types.GET_SIMULATE_TESTS_REQUEST,
    payload: values,
  };
}

export function getSimulateTestsSuccess(questions: QuestionsProps[]): {
  type: string;
  payload: QuestionsProps[];
} {
  return {
    type: types.GET_SIMULATE_TESTS_SUCCESS,
    payload: questions,
  };
}

export function getSimulateTestsFailure(questions: QuestionsProps[]): {
  type: string;
  payload: QuestionsProps[];
} {
  return {
    type: types.GET_SIMULATE_TESTS_FAILURE,
    payload: questions,
  };
}

export function setSimulateAnswerRequest(values: SimulateAnswerProps): {
  type: string;
  payload: SimulateAnswerProps;
} {
  return {
    type: types.SET_SIMULATE_ANSWER_REQUEST,
    payload: values,
  };
}

export function setSimulateAnswerSuccess(): {
  type: string;
  payload: boolean;
} {
  return {
    type: types.SET_SIMULATE_ANSWER_SUCCESS,
    payload: true,
  };
}

export function setSimulateAnswerFailure(): {
  type: string;
  payload: boolean;
} {
  return {
    type: types.SET_SIMULATE_ANSWER_FAILURE,
    payload: false,
  };
}

export function sendSimulateTestDataRequest(values: SimulateSendDataProps): {
  type: string;
  payload: SimulateSendDataProps;
} {
  return {
    type: types.SEND_DATA_REQUEST,
    payload: values,
  };
}

export function sendSimulateTestDataSuccess(): {
  type: string;
  payload: boolean;
} {
  return {
    type: types.SEND_DATA_SUCCESS,
    payload: true,
  };
}

export function sendSimulateTestDataFailure(): {
  type: string;
  payload: boolean;
} {
  return {
    type: types.SEND_DATA_FAILURE,
    payload: false,
  };
}
