import styled from 'styled-components';

export const ContentMobile = styled.div`
  width: calc(100% + 100px);
  height: fit-content;
  background: var(--white);
  margin: -50px;
  padding: 110px 0 0 0;
`;

export const QuestionAreaMobile = styled.div`
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 25px;
  gap: 15px;
`;

export const QuestionContentMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  width: 100%;

  color: var(--black);
  font-style: normal;

  h1 {
    padding: 0px;

    font-weight: 500;
    font-size: 1.125rem;
    line-height: 122%;

    color: var(--gray-800);
  }

  span {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 129%;

    color: var(--gray-400);
  }

  div {
    gap: 15px;
    margin-top: 15px;

    p {
      font-weight: 400;
      font-size: 1rem;
      line-height: 131%;

      color: var(--gray-800);
    }

    img {
      width: 100%;
    }
  }
`;
