import { ButtonHTMLAttributes } from 'react';

import { ButtonStyleProps, ButtonComponent } from './styles';

interface ButtonStoryProps
  extends ButtonStyleProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  label: React.ReactNode;
  backgroundColorGradient?: string;
}

export function Button({
  backgroundColor = '#222B82',
  buttonColor = '#ffffff',
  borderColor = '#222B82',
  backgroundColorGradient,
  width = '180px',
  height = '40px',
  paddingButton = '8px 16px',
  label,
  ...props
}: ButtonStoryProps) {
  return (
    <ButtonComponent
      backgroundColor={
        backgroundColor !== 'none'
          ? `linear-gradient(
        95deg,
        ${backgroundColorGradient || backgroundColor}
          26.19%,
        ${backgroundColor} 102.4%
      )`
          : 'none'
      }
      buttonColor={buttonColor}
      borderColor={borderColor}
      paddingButton={paddingButton}
      width={width}
      height={height}
      {...props}
    >
      {label}
    </ButtonComponent>
  );
}
