import { CuponsType } from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';
import { cuponsRequestProps } from 'src/redux/sagas/cupons';

export function getCuponsRequest(data: cuponsRequestProps): {
  type: string;
  payload: cuponsRequestProps;
} {
  return {
    type: types.GET_CUPONS_REQUEST,
    payload: data,
  };
}

export function getCuponsuccess(cupons: CuponsType): {
  type: string;
  payload: CuponsType;
} {
  return {
    type: types.GET_CUPONS_SUCCESS,
    payload: cupons,
  };
}

export function getCuponsFailure(cupons: CuponsType): {
  type: string;
  payload: CuponsType;
} {
  return {
    type: types.GET_CUPONS_FAILURE,
    payload: cupons,
  };
}
