type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Information: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 8.25V11.745"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.0625 6.2475C9.0625 6.28202 9.03452 6.31 9 6.31C8.96548 6.31 8.9375 6.28202 8.9375 6.2475C8.9375 6.21298 8.96548 6.185 9 6.185C9.03452 6.185 9.0625 6.21298 9.0625 6.2475Z"
        fill={color || '#D81E5B'}
        stroke={color || '#D81E5B'}
      />
      <path
        d="M9 15.9375C12.8315 15.9375 15.9375 12.8315 15.9375 9C15.9375 5.16852 12.8315 2.0625 9 2.0625C5.16852 2.0625 2.0625 5.16852 2.0625 9C2.0625 12.8315 5.16852 15.9375 9 15.9375Z"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

Information.defaultProps = defaultProps;

export default Information;
