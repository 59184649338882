type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const ArrowDown: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.09375 4.90625L9.09375 14.0713"
        stroke={color || 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.09375 14.0938L12.0938 11.0938"
        stroke={color || 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.09375 14.0938L6.09375 11.0938"
        stroke={color || 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

ArrowDown.defaultProps = defaultProps;

export default ArrowDown;
