import Warning from 'src/assets/svgs/warning';

import { Button } from 'src/components/system/Button';

import { DoubleButtonsArea, ModalArea } from '../styles/commom';

interface ModalGetoutRenderProps {
  handleGetoutTest: () => void;
  handleContinueTest: () => void;
}

export function ModalGetoutRender({
  handleContinueTest,
  handleGetoutTest,
}: ModalGetoutRenderProps) {
  return (
    <ModalArea data-testid="modal-get-out">
      <Warning width={105} height={105} />
      <span>
        Você tem certeza que deseja sair da prova? Você não poderá continuar de
        onde parou.
      </span>
      <DoubleButtonsArea>
        <Button
          width="250px"
          backgroundColor="#D81E5B"
          borderColor="#D81E5B"
          buttonColor="#ffffff"
          onClick={handleGetoutTest}
          label={<span>Sair da prova</span>}
        />
        <Button
          width="250px"
          backgroundColor="#ffffff"
          buttonColor="#222B82"
          borderColor="#222B82"
          onClick={handleContinueTest}
          label={<span>Continuar na prova</span>}
        />
      </DoubleButtonsArea>
    </ModalArea>
  );
}
