import styled from 'styled-components';

interface SelectContentMobileProps {
  open: boolean;
}

export const ContainerMobile = styled.div`
  width: calc(100% + 50px);
  height: fit-content;

  margin: 60px -25px 80px -25px;

  padding: 0;
  background: var(--white);

  border-top: 1px solid #d6d6d6;
`;

export const SelectContentMobile = styled.button<SelectContentMobileProps>`
  width: 100%;
  height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 8px 12px;
  margin-top: 30px;

  border: ${({ open }) =>
    open ? '1px solid var(--blue-400)' : '1px solid var(--blue)'};
  border-radius: 5px;

  background: none;

  box-shadow: ${({ open }) =>
    open ? '0px 0px 4px rgba(114, 123, 210, 0.7)' : 'none'};

  svg {
    rotate: ${({ open }) => (open ? '180deg' : '0')};
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 129%;

    color: ${({ open }) => (open ? 'var(--gray-800)' : 'var(--gray-600)')};
  }
`;

export const MultipleQuestionsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  overflow-y: auto;

  padding: 4px 0px;
  margin-top: 10px;

  width: 100%;
  height: 198px;

  border: 1px solid var(--blue-400);
  border-radius: 5px;

  box-shadow: 0px 0px 4px rgba(114, 123, 210, 0.7);

  button {
    width: 100%;
    height: 38px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    padding: 10px 12px;

    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 129%;

    color: var(--gray-800);

    background: none;
    border: none;
  }
`;
