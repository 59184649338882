import styled from 'styled-components';

interface SimulateStyleProps {
  hasFooter: boolean;
}

export const Content = styled.div<SimulateStyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 50px;
  gap: 25px;

  background: ${({ hasFooter }) =>
    hasFooter ? 'var(--blue-400)' : 'var(--white)'};
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  cursor: pointer;

  border: ${({ hasFooter }) =>
    hasFooter ? '2px solid var(--blue-400)' : 'none'};

  transition: 0.5s filter;

  border: 2px solid transparent;

  &:hover {
    border: 2px solid var(--blue-400);

    filter: ${({ hasFooter }) => (hasFooter ? 'brightness(0.90)' : 'none')};
  }

  @media screen and (max-width: 515px) {
    padding: 25px 10px;
  }
`;

export const TopArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftArea = styled.div<SimulateStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  gap: 20px;

  font-style: normal;
  color: ${({ hasFooter }) => (hasFooter ? 'var(--white)' : 'var(--gray-800)')};

  p {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin: 0;
    margin-left: 25px;

    @media screen and (max-width: 450px) {
      margin-left: 8px;
    }
  }

  span {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
`;

export const RightArea = styled.div<SimulateStyleProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 16px;
  gap: 8px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.3125rem;

    color: ${({ hasFooter }) =>
      hasFooter ? 'var(--white)' : 'var(--blue-400)'};
  }

  @media screen and (max-width: 515px) {
    padding: 0;
  }
`;

export const Footer = styled.div`
  width: calc(100% + 100px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -30px;
  height: 95px;

  background: var(--blue-100);

  border-radius: 0px 0px 10px 10px;

  @media screen and (max-width: 515px) {
    width: calc(100% + 20px);
    margin-bottom: -25px;
  }
`;

export const LeftRightAreaFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 50px;

  svg {
    margin-right: 16px;
  }

  p {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.3125rem;

    color: var(--gray-800);
  }

  @media screen and (max-width: 515px) {
    padding: 0 10px;
  }
`;

export const SpanAnswer = styled.span`
  position: absolute;
  top: calc(50% - 13.5px);
  left: 0;

  height: 27px;
  width: 27px;

  background-color: var(--white);
  border: 1.5px solid var(--blue-400);
  border-radius: 100%;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  @media screen and (max-width: 450px) {
    height: 18px;
    width: 18px;
    top: calc(50% - 9px);
  }
`;

export const LabelAnswer = styled.label`
  display: block;
  flex-direction: row;
  align-items: center;

  position: relative;

  cursor: pointer;
  font-size: 1.375rem;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin: 0;

  width: fit-content;

  background: none;
  border-radius: 5px;

  transition: background 0.5s;

  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 131%;

  color: var(--gray-800);

  &:hover {
    background: var(--blue-100);
  }

  input {
    position: absolute;

    opacity: 0;
    height: 0;
    width: 0;

    cursor: pointer;
  }

  input:checked > label {
    border: 2px solid #5768ff;
  }

  &:hover input ~ ${SpanAnswer} {
    background: var(--white);
  }

  input:checked ~ ${SpanAnswer} {
    background: var(--white);
    border: 1.5px solid var(--blue-400);
  }

  input:checked ~ ${SpanAnswer}:after {
    display: block;
  }

  ${SpanAnswer}:after {
    left: 8px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid var(--blue-400);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    @media screen and (max-width: 450px) {
      height: 9px;
      width: 4.5px;
      left: 5px;
      border-width: 0 2px 2px 0;
    }
  }
`;
