import styled from 'styled-components';

export interface ButtonStyleProps {
  backgroundColor?: string;
  buttonColor?: string;
  borderColor?: string;
  width?: string;
  height?: string;
  paddingButton?: string;
}

export const ButtonComponent = styled.button<ButtonStyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ paddingButton }) => paddingButton};
  gap: 8px;

  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3125rem;

  width: ${({ width }) => width};
  height: ${({ height }) => height};

  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ buttonColor }) => buttonColor};
  box-shadow: ${({ borderColor }) =>
    borderColor !== 'none' ? '0px 2px 0px rgba(0, 0, 0, 0.043)' : 'none'};
  border-radius: 5px;
  border: ${({ borderColor }) =>
    borderColor !== 'none' ? `1px solid ${borderColor}` : 'none'};

  cursor: pointer;
  transition: filter 1s;

  &:hover {
    filter: ${({ borderColor }) =>
      borderColor !== 'none' ? 'brightness(0.7)' : 'none'};
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;
