type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Stopwatch: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 21.25C16.5398 21.25 20.22 17.5698 20.22 13.03C20.22 8.49022 16.5398 4.81 12 4.81C7.46025 4.81 3.78003 8.49022 3.78003 13.03C3.78003 17.5698 7.46025 21.25 12 21.25Z"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2.75V4.81"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.72998 5.76001L5.82996 6.86002"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2699 5.76001L18.1699 6.86002"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.06C12.5688 14.06 13.03 13.5989 13.03 13.03C13.03 12.4611 12.5688 12 12 12C11.4311 12 10.97 12.4611 10.97 13.03C10.97 13.5989 11.4311 14.06 12 14.06Z"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.03L14.57 10.46"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.97 2.75H13.03"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

Stopwatch.defaultProps = defaultProps;

export default Stopwatch;
