import styled from 'styled-components';

interface ContainerProps {
  open: boolean;
}

interface ItemResultBoxProps {
  backgroundColor: string;
  borderColor: string;
}

interface ValueResultBoxProps {
  color: string;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  margin: 0 70px;
  padding: 30px 50px;
  gap: 40px;

  border: ${({ open }) =>
    open ? '2px solid var(--blue-400)' : '2px solid var(--gray-300)'};
  box-shadow: ${({ open }) =>
    open
      ? '0px 5px 20px rgba(87, 104, 255, 0.07)'
      : '0px 5px 20px rgba(0, 0, 0, 0.07)'};
  border-radius: 10px;

  transition: border 1s, box-shadow 1s;

  &:hover {
    border: 2px solid var(--blue-400);
    box-shadow: 0px 5px 20px rgba(87, 104, 255, 0.07);
  }

  @media screen and (max-width: 965px) {
    padding: 15px 20px;
  }
`;

export const TitleTopArea = styled.div<ContainerProps>`
  width: 100%;
  padding: 0px;
  gap: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 122%;

    color: var(--gray-700);

    margin-bottom: 0;
  }

  span {
    background: none;
    border: none;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 8px;

    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 131%;

    color: var(--blue-400);

    svg {
      rotate: ${({ open }) => (open ? '180deg' : '0deg')};
    }

    @media screen and (max-width: 965px) {
      padding: 15px 20px;
    }
  }
`;

export const ContentBoxResultArea = styled.div`
  width: 100%;
  padding-top: 30px;

  display: flex;
  flex-direction: column;

  border-top: 2px solid #efefef;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 114%;

    color: var(--gray-800);

    margin-bottom: 30px;
  }
`;

export const GridBoxArea = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  padding: 0px;
  gap: 30px;

  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1160px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 580px) {
    grid-template-columns: 1fr;

    padding-bottom: 20px;
  }
`;

export const ItemResultBox = styled.div<ItemResultBoxProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px;
  gap: 15px;
  width: 100%;
  height: fit-content;
  min-height: 185px;
  min-width: 200px;

  background: var(${({ backgroundColor }) => backgroundColor});

  border: 1px solid var(${({ borderColor }) => borderColor});
  border-radius: 5px;
`;

export const TitleResultBox = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 0.95rem;
  line-height: 131%;

  color: var(--gray-800);

  text-align: center;
`;

export const ValueResultBox = styled.p<ValueResultBoxProps>`
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 114%;

  display: flex;
  align-items: center;

  color: var(${({ color }) => color});

  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 122%;

    display: flex;
    align-items: center;

    color: var(--gray-800);

    &:before {
      content: '/';
      padding: 0 10px;
    }
  }
`;
