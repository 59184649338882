import { useContext, useEffect, useState } from 'react';

import { ModalContext } from 'src/hooks/useModal';
import {
  QuestionsReviewProps,
  AnswersReviewType,
  ResultsReviewType,
} from 'src/redux/types/interfaces';

import { onLoadPage } from '../utils/api';

import { ReviewAreaRender } from './render';

interface ReviewAreaProps {
  idResults: number;
}

export function ReviewArea({ idResults }: ReviewAreaProps) {
  const { setModalChildren, setOpen } = useContext(ModalContext);
  const [results, setResults] = useState<ResultsReviewType[]>([]);
  const [resultsList, setResultsList] = useState<ResultsReviewType[]>([]);

  useEffect(() => {
    (async () => {
      const resultsData = await onLoadPage(idResults);
      setResults(Object.values(resultsData));
      setResultsList(Object.values(resultsData));
    })();
  }, []);

  function handleCloseModal() {
    setModalChildren(null);
    setOpen(false);
  }

  function handleSelectRightItem(
    correta: number,
    marcada: boolean,
    border: boolean,
  ) {
    if (border) {
      if (marcada) {
        if (correta === 1) return 'var(--green)';
        else return 'var(--red)';
      } else {
        if (correta === 1) return 'var(--blue-400)';
        else return 'var(--white)';
      }
    } else {
      if (marcada) {
        if (correta === 1) return 'var(--green-200)';
        else return 'var(--red-200)';
      } else {
        if (correta === 1) return 'var(--blue-200)';
        else return 'var(--gray-200)';
      }
    }
  }
  function filterRespostas(
    { correta, marcada }: AnswersReviewType,
    wrongCorrect: number,
  ) {
    if (marcada === true && correta === wrongCorrect) {
      return true;
    }
  }

  function filterContent(
    { respostas, id_questao }: QuestionsReviewProps,
    wrongCorrect: number,
  ) {
    const res = respostas.find((res: AnswersReviewType) =>
      filterRespostas(res, wrongCorrect),
    );

    if (res?.id_questao !== undefined) return res?.id_questao === id_questao;
  }

  function selectFilter(value: string) {
    if (value === 'erradas') {
      setResults(
        resultsList.filter(({ questao }: ResultsReviewType) =>
          filterContent(questao, 0),
        ),
      );
    } else if (value === 'certas')
      setResults(
        resultsList.filter(({ questao }: ResultsReviewType) =>
          filterContent(questao, 1),
        ),
      );
    else setResults(resultsList);
  }

  return (
    <ReviewAreaRender
      handleCloseModal={handleCloseModal}
      handleSelectRightItem={handleSelectRightItem}
      idResults={idResults}
      results={results}
      selectFilter={selectFilter}
    />
  );
}
