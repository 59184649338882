import styled from 'styled-components';

import { TypographStyleProps } from '../shared/types';

export const TitleTypograph = styled.h1<TypographStyleProps>`
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.375rem;

  color: var(--gray-900);

  margin: 0;
  margin: ${({ marginTop }) => (marginTop !== '' ? `${marginTop} 0 0 0` : '0')};
`;
