export const arrayCourseCategory = [
  'CFP',
  'CEA',
  'CPA_20',
  'CPA_10',
  'CFG',
  'CGA',
  'ANCORD',
  'CGE',
];

export const arrayCourseFamilyLink = [
  'cfp',
  'cea',
  'cpa-20',
  'cpa-10-anbima',
  'cfg',
  'cga',
  'ancord',
  'cge',
  'cnpi',
  'cnpi',
];
