import Warning from 'src/assets/svgs/warning';

import { Button } from 'src/components/system/Button';

import { DoubleButtonsArea, ModalArea } from '../styles/commom';

interface ModalGetoutRenderProps {
  handleContinueTest: () => void;
}

export function ModalFinishWithoutAnseredRender({
  handleContinueTest,
}: ModalGetoutRenderProps) {
  return (
    <ModalArea data-testid="modal-get-out">
      <Warning width={105} height={105} />
      <span>
        Para finalizar a prova é necessário responder todas perguntas.
      </span>
      <Button
        width="250px"
        backgroundColor="#ffffff"
        buttonColor="#222B82"
        borderColor="#222B82"
        onClick={handleContinueTest}
        label={<span>Continuar na prova</span>}
      />
    </ModalArea>
  );
}
