import styled from 'styled-components';

import { TypographStyleProps } from '../shared/types';

export const PageInfoTypograph = styled.h2<TypographStyleProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.875rem;

  color: var(--gray-900);

  margin: 0;
  margin-top: ${({ marginTop }) => (marginTop !== '' ? marginTop : '0')};
`;
