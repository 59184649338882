import { useState } from 'react';

import Information from 'src/assets/svgs/information';

import { TooltipInformations, TooltipMessage } from './styles';

interface TooltipProps {
  infoMessage: string;
  message: string;
}

export function Tooltip({ infoMessage, message }: TooltipProps) {
  const [tooltipView, setTooltipView] = useState(false);

  return (
    <>
      <TooltipMessage
        onMouseEnter={() => setTooltipView(!tooltipView)}
        onMouseLeave={() => setTooltipView(!tooltipView)}
        data-testid="tooltip-area"
      >
        {infoMessage}
        <Information width={18} height={18} color="#D81E5B" />
      </TooltipMessage>
      {tooltipView && <TooltipInformations>{message}</TooltipInformations>}
    </>
  );
}
