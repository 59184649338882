import { LoadingArea, LoadingImage } from './styles';

import LogoLoading from 'src/assets/imgs/logo-loading.svg';

export function Loading() {
  return (
    <LoadingArea>
      <LoadingImage url={LogoLoading}>
        <div></div>
      </LoadingImage>
      <h1>Carregando</h1>
    </LoadingArea>
  );
}
