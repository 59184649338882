import { useContext } from 'react';

import ArrowLeft from 'src/assets/svgs/arrowLeft';
import ArrowRight from 'src/assets/svgs/arrowRight';

import { IsMobileContext } from 'src/hooks/useMobile';

import { Button } from '../Button';

import { ButtonItem, SimulateTestContent } from './styles.desktop';
import { QuestionaryArea, SimulateTestContentMobile } from './styles.mobile';

export interface SimulateTestsFooterProps {
  questionNumber: number;
  handlePassQuestion: () => void;
  handleBackQuestion: () => void;
}

interface SimulateTestesFooterRenderProps extends SimulateTestsFooterProps {
  id_questao?: number;
  length?: number;
  handleOpenComentedQuestion?: () => void;
}

export function SimulateTestsFooter({
  handleBackQuestion,
  handlePassQuestion,
  questionNumber,
  id_questao,
  length,
  handleOpenComentedQuestion,
}: SimulateTestesFooterRenderProps) {
  const { isMobile } = useContext(IsMobileContext);

  if (!isMobile) {
    return (
      <SimulateTestContent data-testid="simulate-test-footer-desktop">
        <Button
          width="225px"
          paddingButton="0"
          backgroundColor="#ffffff"
          buttonColor="#222B82"
          onClick={handleBackQuestion}
          borderColor="#222B82"
          label={
            <ButtonItem>
              <ArrowLeft width={27} height={27} color="#222B82" />
              Anterior
            </ButtonItem>
          }
        />
        <Button
          width="225px"
          paddingButton="0"
          backgroundColor="#ffffff"
          buttonColor="#D81E5B"
          onClick={handleOpenComentedQuestion}
          borderColor="#D81E5B"
          label="Ver questão comentada"
        />
        <Button
          width="225px"
          paddingButton="0"
          backgroundColor="#D81E5B"
          buttonColor="#ffffff"
          onClick={handlePassQuestion}
          borderColor="#D81E5B"
          label={
            <ButtonItem>
              Próxima
              <ArrowRight width={27} height={27} color="#ffffff" />
            </ButtonItem>
          }
        />
      </SimulateTestContent>
    );
  } else {
    return (
      <SimulateTestContentMobile data-testid="simulate-test-footer-mobile">
        <QuestionaryArea>
          <h1>
            Questão {questionNumber + 1} ({id_questao})
          </h1>
          <p>
            Pergunta {questionNumber + 1} de {length}
          </p>
        </QuestionaryArea>
        <div>
          <Button
            width="fit-content"
            data-testid="anterior-mobile"
            height="30px"
            paddingButton="0"
            backgroundColor="#ffffff"
            buttonColor="#222B82"
            onClick={handleBackQuestion}
            borderColor="#ffffff"
            label={<ArrowLeft width={27} height={27} color="#222B82" />}
            disabled={questionNumber <= 0}
          />
          <Button
            width="115px"
            height="30px"
            paddingButton="0"
            backgroundColor="#D81E5B"
            buttonColor="#ffffff"
            onClick={handlePassQuestion}
            borderColor="#D81E5B"
            label={
              <ButtonItem>
                Próxima
                <ArrowRight width={27} height={27} color="#ffffff" />
              </ButtonItem>
            }
          />
        </div>
      </SimulateTestContentMobile>
    );
  }
}
