import { Link } from 'react-router-dom';

import moment from 'moment';

import ArrowRight from 'src/assets/svgs/arrowRight';
import PieChart from 'src/assets/svgs/pieChart';

import {
  Content,
  Footer,
  LabelAnswer,
  LeftArea,
  LeftRightAreaFooter,
  RightArea,
  SpanAnswer,
  TopArea,
} from './styles';
import { CoursesState } from 'src/redux/reducers/courses';
import { useAppSelector } from 'src/redux/useAppSelector';

interface SelectSimulateProps {
  titulo: string;
  id: number;
  questoes_count: number;
}

export function SelectSimulate({
  titulo,
  id,
  questoes_count,
}: SelectSimulateProps) {
  const hasFooter = false;
  const coursesState: CoursesState = useAppSelector(state => state.courses);
  moment.locale('pt-br');

  return (
    <Link to={`questions/lecture/${id}`} data-testid="select-simulates-area">
      <Content hasFooter={hasFooter}>
        <TopArea>
          <LeftArea hasFooter={hasFooter}>
            <LabelAnswer htmlFor="checkboxSelectSimulate">
              <input
                type="checkbox"
                id="checkboxSelectSimulate"
                name="simulate"
                value={id}
                checked={hasFooter}
                onChange={() => {}}
                data-testid="simulate-input"
              />
              <SpanAnswer />
            </LabelAnswer>
            <p>{titulo}</p>
            <span>{questoes_count} questões</span>
          </LeftArea>
          <RightArea hasFooter={hasFooter}>
            <span>Começar</span>
            <ArrowRight
              color={hasFooter ? '#FFFFFF' : '#5768FF'}
              width={18}
              height={18}
            />
          </RightArea>
        </TopArea>
        {/* {hasFooter && (
          <Footer>
            <LeftRightAreaFooter>
              <PieChart color="#5768FF" width={26} height={26} />
              <p>Sua média geral foi de {generalAverage}%</p>
            </LeftRightAreaFooter>
            <LeftRightAreaFooter>
              {String(moment(courseDate).locale('pt-br').format('ll'))}
            </LeftRightAreaFooter>
          </Footer>
        )} */}
      </Content>
    </Link>
  );
}
