type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const ArrowLeft: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.9375 9.5L4.77246 9.5"
        stroke="url(#paint0_linear_4133_4890)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 9.5L7.75 12.5"
        stroke="url(#paint1_linear_4133_4890)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 9.5L7.75 6.5"
        stroke="url(#paint2_linear_4133_4890)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4133_4890"
          x1="7.23613"
          y1="9.703"
          x2="10.8822"
          y2="13.5936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color || '#222B82'} />
          <stop offset="1" stopColor={color || '#000A66'} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4133_4890"
          x1="5.55644"
          y1="10.109"
          x2="8.0747"
          y2="10.4022"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color || '#222B82'} />
          <stop offset="1" stopColor={color || '#000A66'} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4133_4890"
          x1="5.55644"
          y1="7.10899"
          x2="8.0747"
          y2="7.40219"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color || '#222B82'} />
          <stop offset="1" stopColor={color || '#000A66'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

ArrowLeft.defaultProps = defaultProps;

export default ArrowLeft;
