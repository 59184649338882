import { Container, ButtonClose, Content, TopContentArea } from './styles';

import Times from 'src/assets/svgs/times';

import { ResultsReviewType } from 'src/redux/types/interfaces';

import { Loading } from 'src/components/system/Loading';
import { QuestionsReview } from 'src/components/system/QuestionsReview';

import { Select } from 'antd';

interface ReviewAreaProps {
  idResults: number;
  results: ResultsReviewType[];
  handleCloseModal: () => void;
  handleSelectRightItem: (
    correta: number,
    marcada: boolean,
    border: boolean,
  ) => string;
  selectFilter: (value: string) => void;
}

export function ReviewAreaRender({
  handleCloseModal,
  handleSelectRightItem,
  results,
  selectFilter,
}: ReviewAreaProps) {
  return results?.length > 0 ? (
    <Container>
      <TopContentArea>
        <Select
          defaultValue="todos"
          style={{ width: 200 }}
          onChange={selectFilter}
          options={[
            { value: 'todos', label: 'Todos' },
            { value: 'erradas', label: 'Somente questões erradas' },
            { value: 'certas', label: 'Somente questões certas' },
          ]}
        />
        <ButtonClose onClick={handleCloseModal}>
          <Times width={30} height={30} />
        </ButtonClose>
      </TopContentArea>
      <Content>
        {results.map((result: ResultsReviewType, index: number) => (
          <QuestionsReview
            handleSelectRightItem={handleSelectRightItem}
            questionNumber={index}
            result={result}
            key={index}
          />
        ))}
      </Content>
    </Container>
  ) : (
    <Loading />
  );
}
