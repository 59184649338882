import { createContext, ReactNode, useContext, useState } from 'react';

import Modal from 'react-modal';

interface ModalProviderProps {
  children: ReactNode;
}

export interface ModalContextProps {
  setOpen: (value: boolean) => void;
  setModalChildren: (value: ReactNode) => void;
}

export const ModalContext = createContext<ModalContextProps>(
  {} as ModalContextProps,
);

export function ModalProvider({ children }: ModalProviderProps) {
  const [open, setOpen] = useState(false);
  const [modalChildren, setModalChildren] = useState<ReactNode>();

  const value = { setOpen, setModalChildren };

  return (
    <ModalContext.Provider value={value}>
      <Modal
        style={{
          content: {
            background: 'none',
            border: 'none',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
        isOpen={open}
        ariaHideApp={false}
      >
        {modalChildren}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
}
