import { combineReducers } from 'redux';

import { coursesReducer } from './courses';
import { simulatesReducer } from './simulates';
import { userReducer } from './user';
import { simulateTestsReducer } from './simulateTests';
import { resultReducer } from './results';
import { cuponsReducer } from './cupons';
import { rdStationReducer } from './rdstation';
import { trafficReducer } from './traffic';

const rootReducer = combineReducers({
  user: userReducer,
  courses: coursesReducer,
  simulates: simulatesReducer,
  simulateTests: simulateTestsReducer,
  results: resultReducer,
  cupons: cuponsReducer,
  rdstation: rdStationReducer,
  traffic: trafficReducer,
});

export default rootReducer;
