import { useState, useContext } from 'react';

import ArrowUp from 'src/assets/svgs/arrowUp';

import { IsMobileContext } from 'src/hooks/useMobile';
import { SimulateTestsType } from 'src/redux/types/interfaces';

import {
  ButtonAreaDesktop,
  ContainerDesktop,
  QuestionButtonDesktop,
  QuestionsAreaDesktop,
} from './styles.desktop';
import {
  ContainerMobile,
  MultipleQuestionsArea,
  SelectContentMobile,
} from './styles.mobile';

interface TestAreaQuestionsListProps extends SimulateTestsType {
  questionNumber: number;
  handleSelectQuestion: (value: number) => void;
}

export function TestAreaQuestionsList({
  questions,
  questionNumber,
  handleSelectQuestion,
}: TestAreaQuestionsListProps) {
  const [open, setOpen] = useState(false);
  const { isMobile } = useContext(IsMobileContext);

  if (!isMobile)
    return (
      <ContainerDesktop open={open} data-testid="container-desktop">
        <QuestionsAreaDesktop>
          {questions
            .slice(0, open ? questions.length : 17)
            .map((question: any, index: number) => (
              <QuestionButtonDesktop
                key={index}
                onClick={() => handleSelectQuestion(index)}
                selected={questionNumber === index}
                answereds={question.answereds.length !== 0}
              >
                {index + 1}
              </QuestionButtonDesktop>
            ))}
        </QuestionsAreaDesktop>
        <ButtonAreaDesktop open={open}>
          <button
            onClick={() => setOpen(!open)}
            data-testid="open-close-question-area"
          >
            <ArrowUp />
          </button>
        </ButtonAreaDesktop>
      </ContainerDesktop>
    );
  else
    return (
      <ContainerMobile data-testid="container-mobile">
        <SelectContentMobile
          open={open}
          onClick={() => setOpen(!open)}
          data-testid="open-close-question-area"
        >
          <p>
            Questão {questionNumber + 1} de {questions.length}
          </p>
          <ArrowUp
            width={20}
            height={20}
            color={open ? '#5768FF' : '#636363'}
          />
        </SelectContentMobile>

        {open && (
          <MultipleQuestionsArea>
            {questions.map((_: any, index: number) => (
              <button
                onClick={() => {
                  handleSelectQuestion(index);
                  setOpen(false);
                }}
                key={index}
              >
                Questão {index + 1}
              </button>
            ))}
          </MultipleQuestionsArea>
        )}
      </ContainerMobile>
    );
}
