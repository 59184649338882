import styled from 'styled-components';

export const TooltipInformations = styled.div`
  position: absolute;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;

  width: 200px;

  background: var(--gray-200);

  border: 1px solid var(--gray-800);
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  z-index: 100;
`;

export const TooltipMessage = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 22px;

  color: var(--red) !important;

  margin-left: 5px;
`;
