import styled from 'styled-components';

export const SimulateTestsTopbarMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;

  z-index: 100;

  width: 100%;

  padding: 50px 20px 20px 20px;
  margin: -50px;

  gap: 20px;

  background: var(--white);
`;

export const MobileTimer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0px;
  gap: 8px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 131%;
    color: var(--gray-800);

    margin: 0;
  }
`;
