import { useContext } from 'react';

import ArrowLeft from 'src/assets/svgs/arrowLeft';
import Pause from 'src/assets/svgs/pause';
import Stopwatch from 'src/assets/svgs/stopwatch';

import { Title } from 'src/components/typograph/Title';
import { Button } from 'src/components/system/Button';

import { TimerContextProps } from 'src/hooks/useTimer';
import { IsMobileContext } from 'src/hooks/useMobile';

import {
  ButtonItem,
  SimulateTestsTopbarHeader,
  TimerArea,
  TitleTimerArea,
} from './styles.desktop';

import { MobileTimer, SimulateTestsTopbarMobile } from './styles.mobile';
import Close from 'src/assets/svgs/close';

interface SimulateTestsTopbarRenderProps
  extends Omit<TimerContextProps, 'setTime'> {
  handleOpenPauseModal: () => void;
  handleOpenGetoutModal: () => void;
  CourseName: string;
}

export function SimulateTestsTopbarRender({
  time,
  handleOpenPauseModal,
  handleOpenGetoutModal,
  CourseName,
}: SimulateTestsTopbarRenderProps) {
  const { isMobile } = useContext(IsMobileContext);

  if (!isMobile)
    return (
      <SimulateTestsTopbarHeader data-testid="simulate-test-top-desktop">
        <Button
          width="fit-content"
          paddingButton="0"
          backgroundColor="none"
          buttonColor="#5768FF"
          onClick={handleOpenGetoutModal}
          borderColor="none"
          data-testid="goout-button"
          label={
            <ButtonItem>
              <ArrowLeft width={27} height={27} color="#5768FF" />
              Voltar
            </ButtonItem>
          }
        />
        <TitleTimerArea>
          <Title value={`Teste ${CourseName}`} />
          <TimerArea>
            <div>
              <Stopwatch color="#252525" width={24} height={24} />
              <p>
                {('0' + parseInt(String((time / 3600) % 3600))).slice(-2)}:
                {('0' + parseInt(String((time / 60) % 60))).slice(-2)}:
                {('0' + (time % 60)).slice(-2)}
              </p>
            </div>
            <Button
              width="fit-content"
              paddingButton="0"
              backgroundColor="#ffffff"
              buttonColor="#D81E5B"
              onClick={handleOpenPauseModal}
              borderColor="none"
              label={
                <ButtonItem>
                  <Pause width={14} height={14} color="#D81E5B" />
                  Pausar
                </ButtonItem>
              }
            />
          </TimerArea>
        </TitleTimerArea>
      </SimulateTestsTopbarHeader>
    );
  else {
    return (
      <SimulateTestsTopbarMobile data-testid="simulate-test-top-mobile">
        <Button
          width="fit-content"
          paddingButton="0"
          backgroundColor="#fff"
          buttonColor="none"
          onClick={handleOpenGetoutModal}
          borderColor="none"
          data-testid="goout-button"
          label={<Close width={14} height={14} />}
        />
        <MobileTimer>
          <Stopwatch color="#252525" width={24} height={24} />
          <p>
            {('0' + parseInt(String((time / 3600) % 3600))).slice(-2)}:
            {('0' + parseInt(String((time / 60) % 60))).slice(-2)}:
            {('0' + (time % 60)).slice(-2)}
          </p>
        </MobileTimer>
        <Button
          width="fit-content"
          paddingButton="0"
          backgroundColor="#ffffff"
          buttonColor="#D81E5B"
          onClick={handleOpenPauseModal}
          borderColor="none"
          label="Pausar"
        />
      </SimulateTestsTopbarMobile>
    );
  }
}
