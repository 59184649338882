type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Certificate: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.3008 29.5835C27.0663 29.5835 32.5508 24.099 32.5508 17.3335C32.5508 10.568 27.0663 5.0835 20.3008 5.0835C13.5353 5.0835 8.05078 10.568 8.05078 17.3335C8.05078 24.099 13.5353 29.5835 20.3008 29.5835Z"
        stroke={color || '#5AB56E'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3346 24.667L6.16797 31.7836L11.0847 31.517L13.318 35.9003L17.0013 29.667C14.218 28.9503 11.9846 26.9337 10.3346 24.667Z"
        stroke={color || '#5AB56E'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.3346 24.667C28.7013 26.9503 26.4513 28.9337 23.668 29.667L27.3513 35.917L29.5846 31.5337L34.5013 31.8003L30.3346 24.667Z"
        stroke={color || '#5AB56E'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3014 15.7836C27.6181 15.4836 27.4348 14.9336 27.0014 14.8836L22.9847 14.3003C22.8181 14.267 22.6681 14.167 22.5848 14.0003L20.7847 10.3503C20.5847 9.95029 20.0181 9.95029 19.8348 10.3503L18.0347 14.0003C17.9514 14.1503 17.8014 14.267 17.6348 14.3003L13.6181 14.8836C13.1848 14.9503 13.0181 15.4836 13.3181 15.7836L16.2348 18.6169C16.3681 18.7503 16.4181 18.9169 16.3848 19.0836L15.7014 23.0836C15.6181 23.5169 16.0847 23.8503 16.4681 23.6336L20.0681 21.7503C20.2181 21.667 20.4181 21.667 20.5681 21.7503L24.1681 23.6336C24.5514 23.8336 25.0181 23.5003 24.9348 23.0836L24.2514 19.0836C24.2181 18.9169 24.2848 18.7336 24.4014 18.6169L27.3014 15.7836Z"
        stroke={color || '#5AB56E'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

Certificate.defaultProps = defaultProps;

export default Certificate;
