import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1024px;
  width: 100%;

  background: var(--white);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px;

  border-radius: 10px;

  -webkit-box-shadow: 3px 3px 10px 0px #6f6f6f;
  box-shadow: 3px 3px 10px 0px #6f6f6f;
`;

export const TopContentArea = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
`;

export const ButtonClose = styled.button`
  border: 0;
  background: none;
  margin: 0 0 0 auto;
  padding: 0;
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100vh - 300px);

  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: var(--white);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--gray-500);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--gray-700);
  }
`;
