import { createContext, ReactNode, useState, useEffect } from 'react';

interface TimerProviderProps {
  children: ReactNode;
}

export interface TimerContextProps {
  time: number;
  setRunning: (value: boolean) => void;
  setTime: (value: number) => void;
}

export const TimerContext = createContext<TimerContextProps>(
  {} as TimerContextProps,
);

export function TimerProvider({ children }: TimerProviderProps) {
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);

  const value = { time, setRunning, setTime };

  useEffect(() => {
    if (running) {
      setTimeout(() => {
        setTime(time + 1);
      }, 1000);
    }
  }, [running, time]);

  return (
    <TimerContext.Provider value={value}>{children}</TimerContext.Provider>
  );
}
