import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1135px;
  margin: 0 auto;
  padding: 90px 0;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1366px) {
    width: 82%;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  margin-top: 25px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
