type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Timer: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.99984 17.9791C13.1613 17.9791 16.5348 14.6056 16.5348 10.4441C16.5348 6.28265 13.1613 2.90912 8.99984 2.90912C4.83838 2.90912 1.46484 6.28265 1.46484 10.4441C1.46484 14.6056 4.83838 17.9791 8.99984 17.9791Z"
        stroke={color || '#252525'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 1.02081V2.90914"
        stroke={color || '#252525'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33545 3.77997L3.34376 4.78831"
        stroke={color || '#252525'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6641 3.77997L14.6558 4.78831"
        stroke={color || '#252525'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99983 11.3883C9.52128 11.3883 9.944 10.9656 9.944 10.4442C9.944 9.92272 9.52128 9.5 8.99983 9.5C8.47838 9.5 8.05566 9.92272 8.05566 10.4442C8.05566 10.9656 8.47838 11.3883 8.99983 11.3883Z"
        stroke={color || '#252525'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 10.4441L11.3558 8.08832"
        stroke={color || '#252525'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.05566 1.02081H9.944"
        stroke={color || '#252525'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

Timer.defaultProps = defaultProps;

export default Timer;
