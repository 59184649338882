import styled from 'styled-components';

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;

  max-width: 789px;
  width: 100%;

  background: var(--white);
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  div.iframe {
    align-items: center;
    justify-content: center;
    display: flex;

    width: 100%;
    margin-bottom: 50px;

    filter: drop-shadow(0px 7px 18px rgba(0, 0, 0, 0.18));
    border-radius: 5px;

    iframe {
      height: 330px;

      filter: drop-shadow(0px 7px 18px rgba(0, 0, 0, 0.18));
      border-radius: 5px;
    }

    @media screen and (max-width: 800px) {
      iframe {
        width: 100%;
      }
    }
  }

  svg {
    width: 100%;
  }
`;

export const TitleModal = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 155%;
  color: var(--blue-400);

  margin-bottom: 10px;
`;

export const SubtitleModal = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 114%;
  color: var(--gray-700);

  margin-bottom: 15px;
`;

export const TextModal = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 167%;
  color: var(--gray-700);
  margin: 0;

  margin-bottom: 30px;
`;
