type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const MagicWand: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.40274 16.5156L3.24773 15.3607C2.92523 15.0382 2.92523 14.5207 3.24773 14.2057L11.3477 6.10564C11.6702 5.78314 12.1877 5.78314 12.5027 6.10564L13.6577 7.26065C13.9802 7.58315 13.9802 8.10066 13.6577 8.41566L5.55774 16.5156C5.24274 16.8381 4.72524 16.8381 4.40274 16.5156Z"
        stroke={color || '#5768FF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.72021 7.94318L11.8202 10.0432"
        stroke={color || '#5768FF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8203 4.94315L15.3978 4.36566"
        stroke={color || '#5768FF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6226 3.66817L12.8326 2.88068"
        stroke={color || '#5768FF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0952 7.14066L16.8827 6.93066"
        stroke={color || '#5768FF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

MagicWand.defaultProps = defaultProps;

export default MagicWand;
