import * as types from 'src/redux/types';
import { ResultsProps, ResultsRequestProps } from 'src/redux/types/interfaces';

export function getResultsRequest(values: ResultsRequestProps): {
  type: string;
  payload: ResultsRequestProps;
} {
  return {
    type: types.GET_TESTS_RESULTS_REQUEST,
    payload: values,
  };
}

export function getResultsSuccess(results: ResultsProps[]): {
  type: string;
  payload: ResultsProps[];
} {
  return {
    type: types.GET_TESTS_RESULTS_SUCCESS,
    payload: results,
  };
}

export function getResultsFailure(results: ResultsProps[]): {
  type: string;
  payload: ResultsProps[];
} {
  return {
    type: types.GET_TESTS_RESULTS_FAILURE,
    payload: results,
  };
}
