import styled from 'styled-components';

interface ProgressAreaProps {
  isMobile: boolean;
}

export interface ProgressLineProps extends ProgressAreaProps {
  currentValue: number;
  totalValue: number;
}

export const ProgressArea = styled.div<ProgressAreaProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: ${({ isMobile }) => (isMobile ? 'fixed' : 'relative')};

  padding: 0px;

  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '4px' : '8px')};

  background: var(--gray-100);
  border-radius: ${({ isMobile }) => (isMobile ? '0' : '5px')};

  z-index: ${({ isMobile }) => (isMobile ? '10' : '0')};
`;

export const ProgressLine = styled.div<ProgressLineProps>`
  width: ${({ currentValue, totalValue }) =>
    `${parseInt(String((currentValue / totalValue) * 100))}%`};
  height: ${({ isMobile }) => (isMobile ? '4px' : '8px')};

  background: var(--blue-400);
  border-radius: ${({ isMobile }) => (isMobile ? '0' : '5px')};
`;
