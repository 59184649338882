import { useContext } from 'react';

import { TimerContext } from 'src/hooks/useTimer';
import { ModalContext } from 'src/hooks/useModal';

import { ModalPause } from 'src/components/modal/ModalPause';
import { ModalGetout } from 'src/components/modal/ModalGetout';

import { SimulateState } from 'src/redux/reducers/simulates';
import { useAppSelector } from 'src/redux/useAppSelector';

import { arrayCourseCategory } from 'src/shared/utils';

import { SimulateTestsTopbarRender } from './render';

export function SimulateTestsTopbar() {
  const simulatesState: SimulateState = useAppSelector(
    state => state.simulates,
  );
  const { simulates } = simulatesState;
  const { setRunning, time } = useContext(TimerContext);
  const { setModalChildren, setOpen } = useContext(ModalContext);

  const CourseName = simulates
    ? arrayCourseCategory[simulates.id_course_category - 1].replace('_', ' ')
    : '';

  function handleOpenPauseModal() {
    setRunning(false);
    setModalChildren(<ModalPause />);
    setOpen(true);
  }

  function handleOpenGetoutModal() {
    setRunning(false);
    setModalChildren(<ModalGetout />);
    setOpen(true);
  }

  return (
    <SimulateTestsTopbarRender
      handleOpenPauseModal={handleOpenPauseModal}
      handleOpenGetoutModal={handleOpenGetoutModal}
      setRunning={setRunning}
      time={time}
      CourseName={CourseName}
    />
  );
}
