import { all, call, put, takeEvery } from '@redux-saga/core/effects';

import { RdStationResponseProps, RdStationType } from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

import { setRdStationuccess, setRdStationFailure } from 'src/redux/actions';

import api from 'src/services/api';

let rdstationApi: RdStationResponseProps;

export interface rdStationRequestProps {
  token: string;
  body: RdStationType;
}

async function rdStationRequest({ body, token }: rdStationRequestProps) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const request = await api.post('rdstation/simulatoro', body, config);
    const response = await request.data;
    rdstationApi = response;
    return true;
  } catch (e) {
    return false;
  }
}

export function* rdStationData(action: {
  type: string;
  payload: rdStationRequestProps;
}) {
  try {
    yield call(rdStationRequest, action.payload);
    yield put(setRdStationuccess(rdstationApi));
  } catch (e) {
    yield put(setRdStationFailure(rdstationApi));
  }
}

export default all([takeEvery(types.SET_RDSTATION_REQUEST, rdStationData)]);
