import * as types from 'src/redux/types';
import { CoursesType } from 'src/redux/types/interfaces';

export interface CoursesState extends CoursesType {
  loading: boolean;
  error: string;
}

const initialState: CoursesState = {
  courses: [],
  error: '',
  loading: false,
};

export function coursesReducer(
  state = initialState,
  action: { type: string; payload: CoursesType },
) {
  switch (action.type) {
    case types.GET_COURSES_REQUEST:
      return {
        ...state,
        loading: true,
        courses: action.payload,
        error: '',
      };
    case types.GET_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload,
        error: '',
      };
    case types.GET_COURSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
