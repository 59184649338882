import {
  SimulateRequestProps,
  SimulatesType,
} from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

export function getSimulateRequest(values: SimulateRequestProps): {
  type: string;
  payload: SimulateRequestProps;
} {
  return {
    type: types.GET_SIMULATES_REQUEST,
    payload: values,
  };
}

export function getSimulateSuccess(simulate: SimulatesType): {
  type: string;
  payload: SimulatesType;
} {
  return {
    type: types.GET_SIMULATES_SUCCESS,
    payload: simulate,
  };
}

export function getSimulateFailure(simulate: SimulatesType): {
  type: string;
  payload: SimulatesType;
} {
  return {
    type: types.GET_SIMULATES_FAILURE,
    payload: simulate,
  };
}
