import styled from 'styled-components';

export const ModalArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 50px;

  width: 610px;

  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  font-style: normal;
  text-align: center;
  color: var(--gray-700);

  p {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 142%;

    text-align: center;

    margin: 0;
  }

  span {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 150%;
  }

  > svg {
    margin-bottom: -35px;
  }

  @media screen and (max-width: 610px) {
    width: 100%;
  }
`;

export const DoubleButtonsArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  width: 100%;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 131%;

    display: flex;
    align-items: center;
    text-align: center;
  }

  @media screen and (max-width: 610px) {
    flex-direction: column;
    gap: 15px;

    button {
      width: 100%;
    }
  }
`;
