import VideoLoading from 'src/assets/svgs/videoLoading';
import { Button } from 'src/components/system/Button';

import { DoubleButtonsArea } from '../styles/commom';

import { ContainerModal, SubtitleModal, TextModal, TitleModal } from './styles';

interface ModalQuestionRenderProps {
  /* link: string; */
  simulateName: string;
  questionNumber: number;
  handleContinueTest: () => void;
  htmlDecode: (value: string) => string | null;
  courseFamily: string;
}

export function ModalQuestionRender({
  /* link, */
  simulateName,
  questionNumber,
  handleContinueTest,
  htmlDecode,
  courseFamily,
}: ModalQuestionRenderProps) {
  const url =
    process.env.REACT_APP_WEBSITE_URL ||
    'https://develop.academiarafaeltoro.com.br/cursos/';
  return (
    <ContainerModal>
      <TitleModal>{simulateName}</TitleModal>
      <SubtitleModal>Questão {questionNumber} comentada:</SubtitleModal>
      <TextModal>Opa! O vídeo está disponível apenas na versão paga.</TextModal>
      {/* <div
        className="iframe"
        dangerouslySetInnerHTML={{
          __html: htmlDecode(link) || '',
        }}
      /> */}
      <VideoLoading />
      <DoubleButtonsArea>
        <Button
          width="250px"
          backgroundColor="#ffffff"
          buttonColor="#222B82"
          borderColor="#222B82"
          onClick={handleContinueTest}
          label={<span>Voltar</span>}
        />
        <Button
          width="250px"
          backgroundColor="#D81E5B"
          borderColor="#D81E5B"
          buttonColor="#ffffff"
          onClick={() => window.open(`${url}${courseFamily}`)}
          label={<span>Comprar curso completo</span>}
        />
      </DoubleButtonsArea>
    </ContainerModal>
  );
}
