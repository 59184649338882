type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Pause: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.54999 15C2.13999 15 1 13.86 1 12.45V3.54001C1 2.13001 2.13999 0.98999 3.54999 0.98999C4.95999 0.98999 6.10001 2.13001 6.10001 3.54001V12.45C6.09001 13.86 4.94999 15 3.54999 15Z"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4499 15C11.0399 15 9.8999 13.86 9.8999 12.45V3.54001C9.8999 2.13001 11.0399 0.98999 12.4499 0.98999C13.8599 0.98999 14.9999 2.13001 14.9999 3.54001V12.45C14.9999 13.86 13.8599 15 12.4499 15Z"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

Pause.defaultProps = defaultProps;

export default Pause;
