import { all } from 'redux-saga/effects';

import courses from './courses';
import user from './user';
import simulates from './simulates';
import simulateTests from './simulateTests';
import results from './results';
import cupons from './cupons';
import rdstation from './rdstation';

export default function* rootSaga(): Generator<any> {
  yield all([
    courses,
    user,
    simulates,
    simulateTests,
    results,
    cupons,
    rdstation,
  ]);
}
