type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Close: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.25 1.64874L0.75 15.3512"
        stroke={color || '#252525'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 1.64874L14.25 15.3512"
        stroke={color || '#252525'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

Close.defaultProps = defaultProps;

export default Close;
