import { all, call, put, takeEvery } from '@redux-saga/core/effects';

import { UserType } from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

import api from 'src/services/api';

import { verifyUserSuccess, verifyUserFailure } from 'src/redux/actions';

let userApi: UserType;

async function useRequest(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const request = await api.get('simulated/show', config);
    const response = await request.data;
    const userReturnApi = {
      name: response[0][0]?.usuario || '',
      email: response[0][0]?.email || '',
      phone: response[0][0]?.telefone || '',
      id_usuario: response[0][0]?.id_usuario || '',
      last_name: response[0][0]?.last_name || '',
    };
    userApi = {
      id: response[0][0]?.id_usuario,
      token: token,
      user: userReturnApi,
    };
    localStorage.setItem('token', token);
    return true;
  } catch (e: any) {
    localStorage.removeItem('token');
    userApi = { id: '0', token: '', user: { name: '' } };

    if (e?.response?.status === 401) {
      const idmUrl =
        process.env.REACT_APP_REDIRECT_URL ||
        'https://dev.idm.academiarafaeltoro.com.br/';
      const callBackURL = window.location.origin;
      window.location.replace(`${idmUrl}?redirectUrl=${callBackURL}`);
    }

    return false;
  }
}

export function* userData(action: { type: string; payload: string }) {
  try {
    yield call(useRequest, action.payload);
    yield put(verifyUserSuccess(userApi));
  } catch (e) {
    yield put(verifyUserFailure(userApi));
  }
}

export default all([takeEvery(types.GET_USER_REQUEST, userData)]);
