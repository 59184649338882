import * as types from 'src/redux/types';
import { SimulateTestsType } from 'src/redux/types/interfaces';

export interface SimulateTestsStates extends SimulateTestsType {
  loading: boolean;
  error: string;
}

const initialState: SimulateTestsStates = {
  questions: [],
  error: '',
  loading: false,
};

export function simulateTestsReducer(
  state = initialState,
  action: { type: string; payload: SimulateTestsType },
) {
  switch (action.type) {
    case types.GET_SIMULATE_TESTS_REQUEST:
      return {
        ...state,
        loading: true,
        questions: action.payload,
        error: '',
      };
    case types.GET_SIMULATE_TESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        questions: action.payload,
        error: '',
      };
    case types.GET_SIMULATE_TESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SET_SIMULATE_ANSWER_REQUEST:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SET_SIMULATE_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SET_SIMULATE_ANSWER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SEND_DATA_REQUEST:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SEND_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SEND_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
