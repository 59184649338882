import { PageInfo } from 'src/components/typograph/PageInfos';
import { Title } from 'src/components/typograph/Title';
import { Courses } from 'src/components/system/Courses';

import { Container, Content } from './styles';
import { useAppSelector } from 'src/redux/useAppSelector';
import { CoursesState } from 'src/redux/reducers/courses';
import { CoursesProps } from 'src/redux/types/interfaces';

export function HomeRender() {
  const coursesState: CoursesState = useAppSelector(state => state.courses);
  const { courses, loading } = coursesState;

  const title =
    Array.isArray(courses) && courses?.length === 0
      ? 'Simulados não encontrados'
      : 'E aí, meu time!';
  const subtitle =
    Array.isArray(courses) && courses?.length === 0
      ? 'Não encontramos simulados disponíveis para você. Tente novamente mais tarde.'
      : 'Vamos testar seu desempenho? Escolha o tipo de  simulado que você quer fazer:';

  if (loading === false) {
    return (
      <Container>
        <Title value={title} />
        <PageInfo value={subtitle} marginTop="18px" />
        <Content>
          {Array.isArray(courses) &&
            courses.map((lessons: any) =>
              lessons.map(
                ({
                  nome,
                  class_time,
                  id_aula,
                  id_curso,
                  imagem,
                  id_turma,
                }: CoursesProps) => (
                  <Courses
                    key={nome}
                    nome={nome}
                    class_time={class_time}
                    id_aula={id_aula}
                    id_curso={id_curso}
                    imagem={imagem}
                    id_turma={id_turma}
                  />
                ),
              ),
            )}
        </Content>
      </Container>
    );
  } else return null;
}
