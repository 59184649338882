import ArrowRight from 'src/assets/svgs/arrowRight';
import PauseLarge from 'src/assets/svgs/pauseLarge';

import { Button } from 'src/components/system/Button';

import { ModalArea } from '../styles/commom';

import { ButtonItem } from './styles';

interface ModalPauseRenderProps {
  handleCloseStopModal: () => void;
  time: number;
}

export function ModalPauseRender({
  handleCloseStopModal,
  time,
}: ModalPauseRenderProps) {
  return (
    <ModalArea>
      <PauseLarge width={105} height={105} />
      <div>
        <p>
          {('0' + parseInt(String((time / 3600) % 3600))).slice(-2)}:
          {('0' + parseInt(String((time / 60) % 60))).slice(-2)}:
          {('0' + (time % 60)).slice(-2)}
        </p>
        <span>Você pausou a sua prova.</span>
      </div>
      <Button
        width="100%"
        backgroundColor="#D81E5B"
        borderColor="#D81E5B"
        buttonColor="#ffffff"
        onClick={handleCloseStopModal}
        label={
          <ButtonItem>
            Continuar
            <ArrowRight color="#ffffff" width={24} height={24} />
          </ButtonItem>
        }
      />
    </ModalArea>
  );
}
