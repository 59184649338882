import { all, call, put, takeEvery } from '@redux-saga/core/effects';

import { CoursesType } from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

import { getCourseSuccess, getCourseFailure } from 'src/redux/actions';

import api from 'src/services/api';

let coursesApi: CoursesType;

async function courseRequest(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const request = await api.get('simulated/show', config);
    const response = await request.data;
    coursesApi = response;
    return true;
  } catch (e) {
    return false;
  }
}

export function* courseData(action: { type: string; payload: string }) {
  try {
    yield call(courseRequest, action.payload);
    yield put(getCourseSuccess(coursesApi));
  } catch (e) {
    yield put(getCourseFailure(coursesApi));
  }
}

export default all([takeEvery(types.GET_COURSES_REQUEST, courseData)]);
