import styled from 'styled-components';

export const SimulateTestsTopbarHeader = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 0px;
`;

export const TitleTimerArea = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0px;
  margin-top: 25px;
`;

export const TimerArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  gap: 10px;

  width: fit-content;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  border-radius: 5px;

  div {
    width: fit-content;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    gap: 6px;

    p {
      margin: 0;
    }
  }

  button {
    height: fit-content;
  }
`;

export const ButtonItem = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
