import React, { useState } from 'react';
import api from 'src/services/api';
import { Container, SharedButtonArea } from './styles';
import { saveAs } from 'file-saver';
import Shared from 'src/assets/svgs/Shared';

export interface PropsType {
  token: string;
  isStudent: boolean;
  type: 'completa' | 'modular';
  points: [number, number];
  name: string;
  title: string;
}

export const openImageToShare = async (data: File) => {
  saveAs(data, 'image.jpg'); // Put your image URL here.
};

const ShareButton = ({
  token,
  isStudent,
  type,
  points,
  name,
  title,
}: PropsType) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);

    api({
      url: 'simulatoro/share',
      method: 'POST',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        isStudent,
        type,
        points,
        name,
        title,
      },
    })
      .then(response => openImageToShare(response.data))
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      <SharedButtonArea onClick={handleClick}>
        {loading ? (
          'Fazendo download...'
        ) : (
          <>
            Compartilhe com o mundo <Shared width={20} />
          </>
        )}
      </SharedButtonArea>
    </Container>
  );
};

export default ShareButton;
