import { useState } from 'react';

import ArrowDown from 'src/assets/svgs/arrowDown';
import Certificate from 'src/assets/svgs/certificate';
import CheckmarkCircle from 'src/assets/svgs/checkmarkCircle';
import Star from 'src/assets/svgs/star';

import {
  Container,
  ContentBoxResultArea,
  GridBoxArea,
  ItemResultBox,
  TitleResultBox,
  TitleTopArea,
  ValueResultBox,
} from './styles';

interface BoxResultsAreaProps {
  title: string;
  totalQuestion: number;
  percentTotalQuestion: number;
  hits: number;
  percentHits: number;
  academyAverage: number;
  percentAcademyAverage: number;
  missed: number;
  percentMissed: number;
  handleOpenBox?: () => void;
}

export function BoxResultsArea({
  title,
  totalQuestion,
  percentTotalQuestion,
  hits,
  percentHits,
  academyAverage,
  percentAcademyAverage,
  missed,
  percentMissed,
  handleOpenBox,
}: BoxResultsAreaProps) {
  const [open, setOpen] = useState(false);

  return (
    <Container open={open} data-testid="box-result-test">
      <TitleTopArea
        open={open}
        onClick={() => {
          if (handleOpenBox && !open) {
            handleOpenBox();
          }

          setOpen(!open);
        }}
        data-testid="button-open-result-test"
      >
        <h3>{title}</h3>
        <span>
          {open ? 'Fechar resultado' : 'Abrir resultado'}
          <ArrowDown width={18} height={18} color="#5768ff" />
        </span>
      </TitleTopArea>
      {open && (
        <ContentBoxResultArea>
          <h2>Total:</h2>
          <GridBoxArea>
            <ItemResultBox
              borderColor="--blue-400"
              backgroundColor="--blue-200"
            >
              <CheckmarkCircle width={40} height={40} color="#5768FF" />
              <TitleResultBox>Questões totais</TitleResultBox>
              <ValueResultBox color="--blue-400">
                {totalQuestion}
                <span>{percentTotalQuestion}%</span>
              </ValueResultBox>
            </ItemResultBox>
            <ItemResultBox borderColor="--green" backgroundColor="--green-200">
              <Certificate width={45} height={45} color="#5AB56E" />
              <TitleResultBox>Acertos</TitleResultBox>
              <ValueResultBox color="--green">
                {hits}
                <span>{percentHits}%</span>
              </ValueResultBox>
            </ItemResultBox>
            <ItemResultBox
              borderColor="--blue-400"
              backgroundColor="--blue-200"
            >
              <Star width={35} height={35} color="#5768FF" />
              <TitleResultBox>Média recomendada da Academia</TitleResultBox>
              <ValueResultBox color="--blue-400">
                {academyAverage}
                <span>{percentAcademyAverage}%</span>
              </ValueResultBox>
            </ItemResultBox>
            <ItemResultBox borderColor="--red" backgroundColor="--red-200">
              <ArrowDown width={40} height={40} color="#d81e5b" />
              <TitleResultBox>Faltaram</TitleResultBox>
              <ValueResultBox color="--red">
                {missed}
                <span>{percentMissed}%</span>
              </ValueResultBox>
            </ItemResultBox>
          </GridBoxArea>
        </ContentBoxResultArea>
      )}
    </Container>
  );
}
