import { useContext } from 'react';

import TwoSpeechBubbles from 'src/assets/svgs/twoSpeechBubbles';

import { Loading } from 'src/components/system/Loading';

import { IsMobileContext } from 'src/hooks/useMobile';

import { AnswresProps, SimulateTestsType } from 'src/redux/types/interfaces';

import { ProgressBar } from '../ProgressBar';
import {
  SimulateTestsFooter,
  SimulateTestsFooterProps,
} from '../SimulateTestsFooter';

import { AnswersContent, LabelAnswer, SpanAnswer } from './styles.common';
import {
  ContentDesktop,
  QuestionAreaDesktop,
  QuestionContentDesktop,
  QuestionsInfoAreaDesktop,
} from './styles.desktop';
import {
  ContentMobile,
  QuestionAreaMobile,
  QuestionContentMobile,
} from './styles.mobile';

interface TestAreaProps extends SimulateTestsFooterProps, SimulateTestsType {
  length: number;
  handleSelectAnswer: (
    id_resposta: number,
    id_questao: number,
    questionNumber: number,
  ) => void;
  handleOpenComentedQuestion: () => void;
}

export function TestArea({
  questions,
  length,
  handleBackQuestion,
  handlePassQuestion,
  questionNumber,
  handleSelectAnswer,
  handleOpenComentedQuestion,
}: TestAreaProps) {
  if (questions !== undefined) {
    const { enunciado, answers, id_questao, answereds, img_enunciado } =
      questions[questionNumber];
    const { isMobile } = useContext(IsMobileContext);

    if (!isMobile)
      return (
        <ContentDesktop data-testid="test-area-desktop">
          <QuestionAreaDesktop key={questionNumber}>
            <ProgressBar
              currentValue={questionNumber + 1}
              totalValue={length}
            />
            <QuestionsInfoAreaDesktop>
              <TwoSpeechBubbles width={30} height={30} color="#5768FF" />
              <p>
                Pergunta {questionNumber + 1} de {length}
              </p>
            </QuestionsInfoAreaDesktop>
            <QuestionContentDesktop>
              <h1>
                Questão {questionNumber + 1}
                <span>({id_questao})</span>
              </h1>
              <div>
                <p>{enunciado}</p>
                {img_enunciado !== null ? (
                  <img
                    src={`https://arquivos.academiarafaeltoro.com.br/${img_enunciado}`}
                    alt=""
                    style={{ marginTop: 10 }}
                  />
                ) : null}
              </div>
            </QuestionContentDesktop>
            <AnswersContent>
              {answers.map((answer: AnswresProps) => (
                <LabelAnswer
                  key={answer.id_resposta}
                  htmlFor={String(answer.id_resposta)}
                  onClick={e => {
                    e.preventDefault();
                    handleSelectAnswer(
                      answer.id_questao,
                      answer.id_resposta,
                      questionNumber,
                    );
                  }}
                  checkedLabel={answereds[0]?.answer === answer.id_resposta}
                  data-testid={`input-answers-${answer.id_resposta}`}
                >
                  {answer.resposta}
                  <input
                    type="radio"
                    id={String(answer.id_resposta)}
                    name="answer"
                    value={answer.id_resposta}
                    checked={answereds[0]?.answer === answer.id_resposta}
                    onChange={() => {}}
                  />
                  <SpanAnswer />
                </LabelAnswer>
              ))}
              <LabelAnswer
                htmlFor="nao-sei"
                onClick={e => {
                  e.preventDefault();
                  handleSelectAnswer(0, 0, questionNumber);
                }}
                checkedLabel={answereds[0]?.answer === 0}
                data-testid={`input-answers-ano-sei`}
              >
                Não sei
                <input
                  type="radio"
                  id="nao-sei"
                  name="answer"
                  value="nao-sei"
                  checked={answereds[0]?.answer === 0}
                  onChange={() => {}}
                />
                <SpanAnswer />
              </LabelAnswer>
            </AnswersContent>
            <SimulateTestsFooter
              handleBackQuestion={handleBackQuestion}
              handlePassQuestion={handlePassQuestion}
              questionNumber={questionNumber}
              handleOpenComentedQuestion={handleOpenComentedQuestion}
            />
          </QuestionAreaDesktop>
        </ContentDesktop>
      );
    else
      return (
        <ContentMobile data-testid="test-area-mobile">
          <ProgressBar currentValue={questionNumber + 1} totalValue={length} />
          <QuestionAreaMobile>
            <QuestionContentMobile>
              <h1>
                Questão {questionNumber + 1} ({id_questao})
              </h1>
              <span>
                Pergunta {questionNumber + 1} de {length}
              </span>
              <div>
                <p>{enunciado}</p>
                {img_enunciado !== null ? (
                  <img
                    src={`https://arquivos.academiarafaeltoro.com.br/${img_enunciado}`}
                    alt=""
                    style={{ marginTop: 10 }}
                  />
                ) : null}
              </div>
            </QuestionContentMobile>
            <AnswersContent>
              {answers.map((answer: any) => (
                <LabelAnswer
                  key={answer.id_resposta}
                  htmlFor={answer.id_resposta}
                  onClick={e => {
                    e.preventDefault();
                    handleSelectAnswer(
                      answer.id_questao,
                      answer.id_resposta,
                      questionNumber,
                    );
                  }}
                  checkedLabel={answereds[0]?.answer === answer.id_resposta}
                  data-testid={`input-answers-${answer.id_resposta}`}
                >
                  {answer.resposta}
                  <input
                    type="radio"
                    id={answer.id_resposta}
                    name="answer"
                    value={answer.id_resposta}
                    checked={answereds[0]?.answer === answer.id_resposta}
                    onChange={() => {}}
                  />
                  <SpanAnswer />
                </LabelAnswer>
              ))}
              <LabelAnswer
                htmlFor="nao-sei"
                onClick={e => {
                  e.preventDefault();
                  handleSelectAnswer(0, 0, questionNumber);
                }}
                checkedLabel={answereds[0]?.answer === 0}
                data-testid={`input-answers-ano-sei`}
              >
                Não sei
                <input
                  type="radio"
                  id="nao-sei"
                  name="answer"
                  value="nao-sei"
                  checked={answereds[0]?.answer === 0}
                  onChange={() => {}}
                />
                <SpanAnswer />
              </LabelAnswer>
            </AnswersContent>
          </QuestionAreaMobile>
          <SimulateTestsFooter
            handleBackQuestion={handleBackQuestion}
            handlePassQuestion={handlePassQuestion}
            questionNumber={questionNumber}
            id_questao={id_questao}
            length={length}
          />
        </ContentMobile>
      );
  }
  return <Loading />;
}
