import { createContext, ReactNode, useContext, useState } from 'react';

import VerticalLogo from 'src/assets/svgs/VerticalLogo';

import { IsMobileContext } from 'src/hooks/useMobile';

import { Content, FooterBar, Topbar } from './styles';

interface SidebarProps {
  children: ReactNode;
}

interface SidebarContextProps {
  setStartTestSidebar: (value: boolean) => void;
}

export const SidebarContext = createContext({} as SidebarContextProps);

export function Sidebar({ children }: SidebarProps) {
  const { isMobile } = useContext(IsMobileContext);
  const [startTest, setStartTestSidebar] = useState(false);

  const apperSidebar = isMobile && startTest;

  return (
    <SidebarContext.Provider value={{ setStartTestSidebar }}>
      {!apperSidebar && (
        <Topbar isMobile={isMobile}>
          <VerticalLogo width={165} />
        </Topbar>
      )}
      <Content isMobile={isMobile} startTest={startTest}>
        {children}
      </Content>
      {!apperSidebar && (
        <FooterBar isMobile={isMobile}>
          <p>© {new Date().getFullYear()} Academia Rafael Toro</p>
        </FooterBar>
      )}
    </SidebarContext.Provider>
  );
}
