import { createContext, ReactNode, useState } from 'react';

interface UseMobileProviderProps {
  children: ReactNode;
}

interface USeMobileContextProps {
  isMobile: boolean;
  setIsMobile: (value: boolean) => void;
}

export const IsMobileContext = createContext<USeMobileContextProps>(
  {} as USeMobileContextProps,
);

export function IsMobileProvider({ children }: UseMobileProviderProps) {
  const [isMobile, setIsMobile] = useState(window.orientation > -1);

  const value = { isMobile, setIsMobile };

  return (
    <IsMobileContext.Provider value={value}>
      {children}
    </IsMobileContext.Provider>
  );
}
