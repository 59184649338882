import { useContext } from 'react';

import { ModalContext } from 'src/hooks/useModal';
import { TimerContext } from 'src/hooks/useTimer';

import { ModalPauseRender } from './render';

export function ModalPause() {
  const { time, setRunning } = useContext(TimerContext);
  const { setOpen, setModalChildren } = useContext(ModalContext);

  function handleCloseStopModal() {
    setRunning(true);
    setModalChildren(null);
    setOpen(false);
  }

  return (
    <ModalPauseRender time={time} handleCloseStopModal={handleCloseStopModal} />
  );
}
