class simulatoroGtm {
  #window;

  constructor(window) {
    this.#window = window;
  }

  clear() {
    if (this.#window?.dataLayer) {
      try {
        this.#window.dataLayer.push({ ecommerce: null });
      } catch (e) {
        // avoiding any crash
      }
    }
  }
  insert(data) {
    if (this.#window?.dataLayer) {
      try {
        this.#window.dataLayer.push(data);
      } catch (e) {
        // avoiding any crash
      }
    }
  }
}

export default simulatoroGtm;
