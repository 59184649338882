import { Link } from 'react-router-dom';

import Stopwatch from 'src/assets/svgs/stopwatch';
import Modules from 'src/assets/svgs/modules';
import Receipt from 'src/assets/svgs/receipt';
import DesktopMonitor from 'src/assets/svgs/desktopMonitor';
import ArrowLeft from 'src/assets/svgs/arrowLeft';
import ArrowRight from 'src/assets/svgs/arrowRight';

import background from 'src/assets/imgs/background.png';

import { Button } from 'src/components/system/Button';
import { Tooltip } from 'src/components/system/Tooltip';

import {
  ButtonItem,
  ButtonsArea,
  Container,
  Content,
  InformationsArea,
  InstrictionsArea,
  Title,
} from './styles';

import { CourseTypeOfApi, CoursesProps } from 'src/redux/types/interfaces';
import { useAppSelector } from 'src/redux/useAppSelector';
import { UserState } from 'src/redux/reducers/user';

interface InstructionsRenderProps {
  urlGoBack: string;
  handleStartTest: () => void;
  screenName: string;
}

export function Instructions({
  urlGoBack,
  handleStartTest,
  screenName,
}: InstructionsRenderProps) {
  const {
    user: {
      user: { name },
    },
  }: UserState = useAppSelector(state => state.user);
  const params = window.location.pathname.split('/');
  const id_aula = params[5] || 0;
  const id_curso = params[2] || 0;

  const { courses }: CourseTypeOfApi = useAppSelector(state => state.courses);
  const findCurrentCourse = courses.find(
    ([value]: CoursesProps[]) =>
      value.id_aula === Number(id_aula) && value.id_curso === Number(id_curso),
  ) as unknown as CoursesProps[];
  const currentCourse: CoursesProps = findCurrentCourse
    ? findCurrentCourse[0]
    : ({} as CoursesProps);

  const tempoTotal = currentCourse.class_time
    ? `${String(currentCourse.class_time)?.slice(0, 2)}h${String(
        currentCourse.class_time,
      )?.slice(3, 5)}min`
    : '03h50min';

  return (
    <Container background={background} data-testid="instructions-area">
      <Content>
        <Title fontSize="1.75rem">
          O seu simulado está prestes a começar{name ? `, ${name}...` : '...'}
        </Title>
        <InstrictionsArea>
          <Title fontSize="1.5rem">Se liga nas instruções! 👇</Title>
          <InformationsArea>
            <Stopwatch width={24} height={24} color="#D81E5B" />
            <span>O simulado tem duração máxima de {tempoTotal}.</span>
          </InformationsArea>
          <InformationsArea>
            <Modules width={24} height={24} color="#D81E5B" />
            <span>O teste é dividido por módulos.</span>
          </InformationsArea>
          <InformationsArea>
            <DesktopMonitor width={24} height={24} color="#D81E5B" />
            <span>
              Se a sua tela ficar inativa por mais de 20 minutos, a sua prova
              será finalizada.
              <Tooltip
                infoMessage="[Saiba mais]"
                message="Tela inativa é quando não há interação com a prova ou quando a janela não está em foco."
              />
            </span>
          </InformationsArea>
          <InformationsArea>
            <Receipt width={24} height={24} color="#D81E5B" />
            <span>
              O resultado ficará disponível na plataforma logo após a conclusão
              do teste.
            </span>
          </InformationsArea>
        </InstrictionsArea>
        <ButtonsArea>
          <Link to={urlGoBack} style={{ width: '100%' }}>
            <Button
              width="100%"
              backgroundColor="#ffffff"
              buttonColor="#222B82"
              label={
                <ButtonItem>
                  <ArrowLeft color="#222B82" width={24} height={24} />
                  Voltar
                </ButtonItem>
              }
            />
          </Link>
          <Button
            width="100%"
            backgroundColor="#D81E5B"
            buttonColor="#ffffff"
            onClick={handleStartTest}
            label={
              <ButtonItem>
                Começar o simulado
                <ArrowRight color="#ffffff" width={24} height={24} />
              </ButtonItem>
            }
          />
        </ButtonsArea>
      </Content>
    </Container>
  );
}
