type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const ArrowRight: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.75004 9.5L13.915 9.5"
        stroke={color || '#ffffff'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9375 9.5L10.9375 6.5"
        stroke={color || '#ffffff'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9375 9.5L10.9375 12.5"
        stroke={color || '#ffffff'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

ArrowRight.defaultProps = defaultProps;

export default ArrowRight;
