type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Checkmark: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M51.9998 92.0833C29.8565 92.0833 11.9165 74.1433 11.9165 52C11.9165 29.8566 29.8565 11.9166 51.9998 11.9166C74.1432 11.9166 92.0832 29.8566 92.0832 52C92.0832 74.1433 74.1432 92.0833 51.9998 92.0833Z"
        stroke={color || '#62CF7A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.0065 58.8466L42.3799 51.22"
        stroke={color || '#62CF7A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.2597 43.5933L50.0063 58.8466"
        stroke={color || '#62CF7A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

Checkmark.defaultProps = defaultProps;

export default Checkmark;
