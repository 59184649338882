import { TypographProps } from '../shared/types';

import { SubTitleTypograph } from './styles';

export function SubTitle({
  value,
  marginTop = '',
  fontWeight = '400',
}: TypographProps) {
  return (
    <SubTitleTypograph marginTop={marginTop} fontWeight={fontWeight}>
      {value}
    </SubTitleTypograph>
  );
}
