import { useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';

import gtm from './shared/gtm';

import './App.css';

import 'react-toastify/dist/ReactToastify.css';

import { TimerProvider } from './hooks/useTimer';
import { ModalProvider } from './hooks/useModal';
import { IsMobileProvider } from './hooks/useMobile';
import { PrivateRouter } from './routes/PrivateRouter';

import { ToastContainer } from 'react-toastify';

const App = () => {
  useEffect(() => {
    gtm();
  }, []);

  return (
    <BrowserRouter>
      <IsMobileProvider>
        <TimerProvider>
          <ModalProvider>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <PrivateRouter />
            <ToastContainer />
          </ModalProvider>
        </TimerProvider>
      </IsMobileProvider>
    </BrowserRouter>
  );
};

export default App;
