import { all, call, put, takeEvery } from '@redux-saga/core/effects';

import { ResultsProps, ResultsRequestProps } from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

import { getResultsSuccess, getResultsFailure } from 'src/redux/actions';

import api from 'src/services/api';

let resultsApi: ResultsProps[];

async function resultsRequest({
  token,
  id_curso,
  id_turma,
}: ResultsRequestProps) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const request = await api.get(
      `services/student/tests/${id_turma}/course/${id_curso}`,
      config,
    );
    const response = await request.data;
    resultsApi = response;
    return true;
  } catch (e) {
    return false;
  }
}

export function* resultsData(action: {
  type: string;
  payload: ResultsRequestProps;
}) {
  try {
    yield call(resultsRequest, action.payload);
    yield put(getResultsSuccess(resultsApi));
  } catch (e) {
    yield put(getResultsFailure(resultsApi));
  }
}

export default all([takeEvery(types.GET_TESTS_RESULTS_REQUEST, resultsData)]);
