import styled from 'styled-components';

interface LabelAnswerProps {
  checkedLabel?: boolean;
}

export const AnswersContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  margin-top: 30px;
  padding: 0px;

  width: 100%;

  cursor: pointer;
`;

export const SpanAnswer = styled.span`
  position: absolute;
  top: calc(50% - 7px);
  left: 18px;

  height: 14px;
  width: 14px;

  background-color: none;
  border: 1.5px solid var(--gray-800);
  border-radius: 100%;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const LabelAnswer = styled.label<LabelAnswerProps>`
  display: block;
  flex-direction: row;
  align-items: center;
  padding: 18px 42px;
  gap: 10px;

  position: relative;

  cursor: pointer;
  font-size: 1.375rem;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin: 0;

  width: 100%;

  background: ${({ checkedLabel }) =>
    checkedLabel ? 'var(--blue-100)' : 'var(--gray-100)'};
  border-radius: 5px;

  transition: background 0.5s;

  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 131%;

  color: var(--gray-800);

  &:hover {
    background: var(--blue-100);
  }

  input {
    position: absolute;

    opacity: 0;
    height: 0;
    width: 0;

    cursor: pointer;
  }

  border: ${({ checkedLabel }) =>
    checkedLabel ? '2px solid var(--blue-400)' : '2px solid transparent'};

  &:hover input ~ ${SpanAnswer} {
    background: none;
  }

  input:checked ~ ${SpanAnswer} {
    background: none;
    border: 1.5px solid var(--blue-400);
  }

  input:checked ~ ${SpanAnswer}:after {
    display: block;
  }

  ${SpanAnswer}:after {
    left: 3.5px;
    top: 0.5px;
    width: 4px;
    height: 8px;
    border: solid var(--blue-400);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
