export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GET_COURSES_REQUEST = 'GET_COURSES_REQUEST';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_FAILURE = 'GET_COURSES_FAILURE';

export const GET_SIMULATES_REQUEST = 'GET_SIMULATES_REQUEST';
export const GET_SIMULATES_SUCCESS = 'GET_SIMULATES_SUCCESS';
export const GET_SIMULATES_FAILURE = 'GET_SIMULATES_FAILURE';

export const GET_SIMULATE_TESTS_REQUEST = 'GET_SIMULATE_TESTS_REQUEST';
export const GET_SIMULATE_TESTS_SUCCESS = 'GET_SIMULATE_TESTS_SUCCESS';
export const GET_SIMULATE_TESTS_FAILURE = 'GET_SIMULATE_TESTS_FAILURE';

export const SET_SIMULATE_ANSWER_REQUEST = 'SET_SIMULATE_ANSWER_REQUEST';
export const SET_SIMULATE_ANSWER_SUCCESS = 'SET_SIMULATE_ANSWER_SUCCESS';
export const SET_SIMULATE_ANSWER_FAILURE = 'SET_SIMULATE_ANSWER_FAILURE';

export const SEND_DATA_REQUEST = 'SEND_DATA_REQUEST';
export const SEND_DATA_SUCCESS = 'SEND_DATA_SUCCESS';
export const SEND_DATA_FAILURE = 'SEND_DATA_FAILURE';

export const GET_TESTS_RESULTS_REQUEST = 'GET_TESTS_RESULTS_REQUEST';
export const GET_TESTS_RESULTS_SUCCESS = 'GET_TESTS_RESULTS_SUCCESS';
export const GET_TESTS_RESULTS_FAILURE = 'GET_TESTS_RESULTS_FAILURE';

export const GET_CUPONS_REQUEST = 'GET_CUPONS_REQUEST';
export const GET_CUPONS_SUCCESS = 'GET_CUPONS_SUCCESS';
export const GET_CUPONS_FAILURE = 'GET_CUPONS_FAILURE';

export const SET_RDSTATION_REQUEST = 'SET_RDSTATION_REQUEST';
export const SET_RDSTATION_SUCCESS = 'SET_RDSTATION_SUCCESS';
export const SET_RDSTATION_FAILURE = 'SET_RDSTATION_FAILURE';

export const GET_TRAFFIC_VALUES = 'GET_TRAFFIC_VALUES';
export const SET_TRAFFIC_VALUES = 'SET_TRAFFIC_VALUES';
