import { CoursesType } from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

export function getCourseRequest(token: string): {
  type: string;
  payload: string;
} {
  return {
    type: types.GET_COURSES_REQUEST,
    payload: token,
  };
}

export function getCourseSuccess(course: CoursesType): {
  type: string;
  payload: CoursesType;
} {
  return {
    type: types.GET_COURSES_SUCCESS,
    payload: course,
  };
}

export function getCourseFailure(course: CoursesType): {
  type: string;
  payload: CoursesType;
} {
  return {
    type: types.GET_COURSES_FAILURE,
    payload: course,
  };
}
