import styled from 'styled-components';

interface ComponentsProps {
  startTest: boolean;
  isMobile: boolean;
}

interface TopFooterProps {
  isMobile: boolean;
}

export const Topbar = styled.div<TopFooterProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 70px;

  background: var(--white);

  filter: drop-shadow(0px 8px 14px rgba(0, 0, 0, 0.17));

  position: fixed;

  z-index: 10;

  padding: ${({ isMobile }) => (isMobile ? '0 20px' : '0 70px')};
`;

export const FooterBar = styled.div<TopFooterProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  align-items: center;

  gap: 10px;

  width: 100%;
  height: 70px;

  background: var(--white);

  bottom: 0;
  position: fixed;

  padding: ${({ isMobile }) => (isMobile ? '0 20px' : '0 70px')};

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;

    color: var(--gray-400);
  }
`;

export const Content = styled.div<ComponentsProps>`
  width: 100%;
  height: fit-content;

  padding: ${({ startTest, isMobile }) =>
    startTest && isMobile ? '0' : '70px 0'};
`;
