import { SelectSimulate } from 'src/components/system/SelectSimulate';
import { PageInfo } from 'src/components/typograph/PageInfos';
import { Title } from 'src/components/typograph/Title';

import { Button } from 'src/components/system/Button';

import ArrowLeft from 'src/assets/svgs/arrowLeft';

import { ButtonItem, Container, Content } from './styles';
import { LessonProps, SimulatesProps } from 'src/redux/types/interfaces';

interface SimulatesRenderProps {
  pageName: string;
  aulas: LessonProps[];
  handleGoBack: () => void;
}

export function SimulatesRender({
  pageName,
  aulas,
  handleGoBack,
}: SimulatesRenderProps) {
  return (
    <Container>
      <Button
        width="fit-content"
        paddingButton="0 0 43px 0"
        backgroundColor="none"
        buttonColor="#5768FF"
        onClick={handleGoBack}
        borderColor="none"
        label={
          <ButtonItem>
            <ArrowLeft width={27} height={27} color="#5768FF" />
            Voltar
          </ButtonItem>
        }
      />
      <Title value={pageName} />
      <PageInfo
        value="Selecione um simulado abaixo para começar."
        marginTop="20px"
      />
      <Content>
        {Array.isArray(aulas) &&
          aulas.map(
            ({ titulo, id_aula, questoes_count }: LessonProps, index) => (
              <SelectSimulate
                key={index}
                titulo={titulo}
                id={id_aula}
                questoes_count={questoes_count}
              />
            ),
          )}
      </Content>
    </Container>
  );
}
