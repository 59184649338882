import api from 'src/services/api';

export async function onLoadPage(idResults: number) {
  const token = localStorage.getItem('token') || '';
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const results = await api.get(
    `services/student/test/questions/${idResults}`,
    config,
  );

  return results.data;
}
