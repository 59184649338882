type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Modules: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.22 9.21999L17.89 10.01L12.61 13.14C12.21 13.36 11.71 13.36 11.32 13.14L6.03997 10.01L4.70998 9.21999C3.81998 8.69999 3.81998 7.36005 4.70998 6.84005L11.33 2.91C11.73 2.69 12.23 2.69 12.62 2.91L19.24 6.84005C20.11 7.36005 20.11 8.70999 19.22 9.21999Z"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8301 11.59C20.0001 12.17 19.8001 12.85 19.2201 13.19L12.6001 17.12C12.2001 17.34 11.7001 17.34 11.3101 17.12L4.69008 13.19C4.15008 12.87 3.94007 12.25 4.06007 11.69"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8502 15.62C19.9902 16.19 19.7802 16.83 19.2302 17.16L12.6102 21.09C12.2102 21.31 11.7102 21.31 11.3202 21.09L4.7002 17.16C4.1902 16.86 3.9702 16.29 4.0502 15.76"
        stroke={color || '#D81E5B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

Modules.defaultProps = defaultProps;

export default Modules;
