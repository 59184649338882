import styled from 'styled-components';

interface LeftRightAreaContentProps {
  width?: string;
  boxShadow?: string;
  padding?: string;
  alignItems?: string;
  gap?: string;
}

interface PieGraphProps {
  percent: number;
}

export const Container = styled.div`
  width: 100%;
  max-width: 1135px;
  margin: 0 auto;
  padding: 0;

  display: flex;
  flex-direction: column;

  background: none;

  @media screen and (max-width: 965px) {
    max-width: 100%;
    padding: 0;
    height: 100vh;

    background: var(--white);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 50px 70px;
  gap: 40px;
  margin: 75px 0;

  width: 100%;

  background: var(--white);
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  @media screen and (max-width: 965px) {
    max-width: 100%;
    padding: 0;
    margin-top: 0;

    background: var(--white);

    box-shadow: none;
    border-radius: 0;

    > h2 {
      margin: -50px 50px;
    }
  }
`;

export const TwoColumnsGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  padding: 0px;
  gap: 10px;

  @media screen and (max-width: 965px) {
    flex-direction: column;
    padding: 20px;
    gap: 50px;
  }
`;

export const LeftRightAreaContent = styled.div<LeftRightAreaContentProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : '0px')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};

  padding: ${({ padding }) => (padding ? padding : '0px')};
  width: ${({ width }) => (width ? width : '100%')};

  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : 'none')};

  border-radius: 10px;

  @media screen and (max-width: 965px) {
    width: 100%;
  }
`;

export const InformationAboutWhatDoing = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 139%;

  color: var(--gray-700);

  margin: 10px 0;

  &.first-item {
    margin-top: 30px;
  }
`;

export const CourseTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 20px;

  width: fit-content;
  height: 42px;

  background: var(--blue-400);
  border-radius: 5px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 122%;
    color: var(--white);
  }
`;

export const CupomArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 32px;
  gap: 28px;

  width: 100%;

  border: 1px dashed var(--gray-500);
  border-radius: 10px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;

    display: flex;
    align-items: center;
    text-align: center;

    color: var(--gray-900);
  }

  div {
    position: relative;

    width: 100%;
    height: 55px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background: linear-gradient(
      96.64deg,
      var(--blue-600) 26.19%,
      var(--blue) 102.4%
    );

    border-radius: 10px;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 129%;

      color: var(--gray-200);
    }

    &:after {
      content: '';
      position: absolute;

      width: 15px;
      height: 15px;

      background: #fff;
      border-radius: 50%;
      left: -7.5px;
    }

    &:before {
      content: '';
      position: absolute;

      width: 15px;
      height: 15px;

      background: #fff;
      border-radius: 50%;
      right: -7.5px;
    }
  }

  button {
    background: none;
    cursor: pointer;
    border: none;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 129%;
    gap: 5px;

    text-align: center;

    color: var(--blue-400);
  }
`;

export const WatchArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 10px;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 35px;

    color: var(--red);

    margin: 0;
  }
`;

export const InfomationsAboutTest = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 139%;

  color: var(--gray-700);

  span {
    font-weight: 600;
  }
`;

export const PieGraph = styled.div<PieGraphProps>`
  position: relative;
  width: 165px;
  height: 165px;
  background-image: ${({ percent }) => `conic-gradient(
    var(--orange) 0deg ${(360 * percent) / 100}deg,
    var(--gray-100) ${(360 * percent) / 100}deg 360deg
  )`};
  border-radius: 50%;

  h1 {
    position: absolute;

    width: 85%;
    border-radius: 50%;
    height: 85%;

    background: var(--white);

    left: 7.5%;
    top: 7.5%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-style: normal;
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 114%;

    color: var(--gray-900);
  }
`;

export const FooterContent = styled.div`
  width: 100%;
  height: fit-content;

  margin-top: 30px;
  padding-top: 40px;
  border-top: 2px solid #efefef;

  @media screen and (max-width: 965px) {
    padding: 40px 50px;
  }
`;

export const ResultsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 40px;

  gap: 40px;
`;

export const ButtonFooter = styled.button`
  background: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 300px;
  height: 40px;
  padding: 8px 16px;
  margin: 0 auto;

  border: 1px solid var(--red);

  filter: drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.016));
  border-radius: 5px;

  cursor: pointer;

  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 131%;

  color: var(--red);

  transition: 1s filter;

  &:hover {
    filter: brightness(0.7);
  }

  @media screen and (max-width: 758px) {
    margin-bottom: 100px;
  }
`;

export const ButtonResultsTest = styled.button`
  width: 100%;
  height: 55px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: linear-gradient(
    96.64deg,
    var(--blue-600) 26.19%,
    var(--blue) 102.4%
  );

  border-radius: 10px;
  border: 0;

  margin: 30px 0;

  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 129%;

  color: var(--gray-200);

  transition: filter 2s;

  &:hover {
    filter: brightness(0.6);
  }
`;
