import { ResultsProps } from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

export interface ResultState {
  result: ResultsProps[];
  loading: boolean;
  error: string;
}

const initialState: ResultState = {
  result: [],
  error: '',
  loading: false,
};

export function resultReducer(
  state = initialState,
  action: { type: string; payload: ResultsProps[] },
) {
  switch (action.type) {
    case types.GET_TESTS_RESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        result: action.payload,
        error: '',
      };
    case types.GET_TESTS_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
        error: '',
      };
    case types.GET_TESTS_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    default:
      return state;
  }
}
