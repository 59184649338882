import * as types from 'src/redux/types';
import { RdStationType } from 'src/redux/types/interfaces';

export interface rdStationState {
  rdstation: RdStationType;
  loading: boolean;
  error: string;
}

const initialState: rdStationState = {
  rdstation: {
    traffic_medium: '',
    traffic_campaign: '',
    traffic_value: '',
    traffic_source: '',
    conversion_identifier: '',
    tags: '',
  },
  error: '',
  loading: false,
};

export function rdStationReducer(
  state = initialState,
  action: { type: string; payload: RdStationType },
) {
  switch (action.type) {
    case types.SET_RDSTATION_REQUEST:
      return {
        ...state,
        loading: true,
        rdstation: action.payload,
        error: '',
      };
    case types.SET_RDSTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        rdstation: action.payload,
        error: '',
      };
    case types.SET_RDSTATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
