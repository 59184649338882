import styled from 'styled-components';

export const ContentDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 50px;
  gap: 50px;
  margin-top: 50px;

  width: 100%;

  background: var(--white);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 10px 10px 0px 0px;
`;

export const QuestionAreaDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
`;

export const QuestionsInfoAreaDesktop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;

  margin-left: auto;
  margin-top: 20px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 122%;

    color: var(--gray-900);

    margin: 0;
  }
`;

export const QuestionContentDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;

  width: 100%;

  color: var(--black);
  font-style: normal;

  h1 {
    padding: 0px;

    font-weight: 600;
    font-size: 1.5rem;
    line-height: 129%;

    span {
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 122%;

      color: var(--gray-400);

      margin-left: 17px;
    }
  }

  div {
    gap: 15px;
    p {
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 139%;
    }

    img {
      width: 100%;
    }
  }
`;
