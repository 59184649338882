import * as types from 'src/redux/types';
import { UserType } from 'src/redux/types/interfaces';

export function verifyUserRequest(token: string): {
  type: string;
  payload: string;
} {
  return {
    type: types.GET_USER_REQUEST,
    payload: token,
  };
}

export function verifyUserSuccess(user: UserType): {
  type: string;
  payload: UserType;
} {
  return {
    type: types.GET_USER_SUCCESS,
    payload: user,
  };
}

export function verifyUserFailure(user: UserType): {
  type: string;
  payload: UserType;
} {
  return {
    type: types.GET_USER_FAILURE,
    payload: user,
  };
}
