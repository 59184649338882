import styled from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: ${({ isMobile }) => (isMobile ? '100%' : '1135px')};
  margin: 0 auto;
  padding: 50px;

  display: flex;
  flex-direction: column;

  background: ${({ isMobile }) => (isMobile ? 'var(--white)' : 'none')}; ;
`;
