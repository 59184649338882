import { all, call, put, takeEvery } from '@redux-saga/core/effects';

import {
  SimulateRequestProps,
  SimulatesType,
} from 'src/redux/types/interfaces';
import * as types from 'src/redux/types';

import { getSimulateSuccess, getSimulateFailure } from 'src/redux/actions';

import api from 'src/services/api';

let simulatesApi: SimulatesType;

async function simulateRequest({ token, id_curso }: SimulateRequestProps) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const request = await api.get(
      `simulated/list/simulations/${id_curso}`,
      config,
    );
    const response = await request.data;
    simulatesApi = response;
    return true;
  } catch (e) {
    return false;
  }
}

export function* simulateData(action: {
  type: string;
  payload: SimulateRequestProps;
}) {
  try {
    yield call(simulateRequest, action.payload);
    yield put(getSimulateSuccess(simulatesApi));
  } catch (e) {
    yield put(getSimulateFailure(simulatesApi));
  }
}

export default all([takeEvery(types.GET_SIMULATES_REQUEST, simulateData)]);
